import React from "react";
import { SelectboxComponent } from "../../../../components/select-box/select-box-component";
import useStateHook from "../../../../hooks/state";

export function Step2(props) {
  const [selectedState, setSelectedState, stateOptions] = useStateHook();

  const handleChange = (selectedState) => {
    setSelectedState(selectedState);
    props.onChange({ state: selectedState });
    props.setHasValue((value) => {
      return { ...value, 2: !!(selectedState !== "Select State") };
    });
  };

  return (
    <>
      <h2>Where do you live?</h2>
      <p className="lead">Select your state.</p>
      <div className="content sm">
        <SelectboxComponent
          options={stateOptions}
          value={selectedState}
          onChange={handleChange}
          disableOptionValue="Select State"
        ></SelectboxComponent>
      </div>
    </>
  );
}
