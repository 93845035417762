




export function ContactInfoIcon() {
	return (
		<svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M3.42578 0.000644498C3.40723 -0.00248313 3.4001 0.00624109 3.38293 0.0166939L2.58604 0.483605C0.833747 1.79719 0.0860526 3.50631 0.00662536 5.38091C-0.0721964 7.25951 0.557162 9.30224 1.63416 11.1001C1.63724 11.1029 1.63861 11.1077 1.64137 11.1105L3.81888 14.941C3.82196 14.9456 3.82471 14.9498 3.82609 14.9531C4.82127 16.7989 6.24561 18.3739 7.8858 19.243C9.5233 20.1122 11.3538 20.3108 13.3513 19.4261L14.148 18.9576C14.1836 18.9357 14.1881 18.95 14.2 18.8095C14.2113 18.669 14.1767 18.4167 14.0888 18.1358C13.9116 17.5723 13.5444 16.8981 13.2756 16.4997C13.2654 16.4876 13.2568 16.4733 13.248 16.4601C12.6718 15.4462 12.1375 14.8391 11.7272 14.5891C11.3152 14.3388 11.0705 14.3444 10.666 14.5826L9.61749 15.1962C8.93619 15.5957 8.16419 15.5209 7.55362 15.256C6.94307 14.9911 6.44624 14.5612 6.15363 14.0477L3.57297 9.50603C3.28207 8.9908 3.16403 8.33961 3.24333 7.66957C3.32377 7.00068 3.64377 6.28373 4.32618 5.88454L5.37329 5.26849C5.7781 5.03137 5.90446 4.82101 5.8962 4.33326C5.88932 3.8461 5.64152 3.07218 5.06531 2.05665C5.05842 2.04348 5.05097 2.03023 5.04376 2.01533C4.83913 1.57937 4.44899 0.919689 4.05716 0.482234C3.86088 0.264202 3.66185 0.104617 3.53547 0.0447786C3.4735 0.013831 3.4445 0.00181471 3.4257 0.000662181L3.42578 0.000644498Z" fill="#668BB4"/>
			<path d="M6.91902 3.50341C6.98812 3.78293 7.02992 4.05206 7.03429 4.31246C7.03899 4.64457 6.97094 4.96085 6.8457 5.2471L12.2432 9.57678C12.4047 9.7074 12.5974 9.76896 12.7703 9.78633C12.9431 9.76863 13.1334 9.7074 13.2946 9.57678L20.8667 3.50293L6.91901 3.50301L6.91902 3.50341Z" fill="#668BB4"/>
			<path d="M21.9301 4.09863L13.9904 10.471C13.639 10.7531 13.2231 10.9101 12.8039 10.9346C12.7807 10.9362 12.7591 10.9362 12.7376 10.9346C12.3183 10.9099 11.9022 10.7531 11.5494 10.471L6.14461 6.13126C6.07827 6.17957 6.01192 6.22788 5.93999 6.26862L4.89449 6.88418C4.58479 7.06591 4.42222 7.39068 4.37328 7.80591C4.32265 8.2217 4.42828 8.69563 4.55911 8.92715L7.13977 13.4673C7.26947 13.6983 7.61926 14.0264 7.99806 14.1899C8.37718 14.3539 8.73823 14.3741 9.04793 14.191L10.0964 13.5786C10.7775 13.1785 11.6142 13.1715 12.3113 13.5957C12.9992 14.016 13.5929 14.7677 14.2123 15.8492H20.9454C21.5286 15.8492 21.9997 15.4393 21.9997 14.9316L21.9996 4.42043C21.9996 4.30668 21.9736 4.1982 21.9288 4.09877L21.9301 4.09863Z" fill="#668BB4"/>
		</svg>
	)
}

