import "./chat.scss";

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import SendbirdProvider from "@sendbird/uikit-react/SendbirdProvider";
import { GroupChannelList } from "@sendbird/uikit-react/GroupChannelList";
import { useGroupChannelContext } from "@sendbird/uikit-react/GroupChannel/context";
import { GroupChannel } from "@sendbird/uikit-react/GroupChannel";

import {
  AstraIcon,
  ChevronRight,
  ChevronLeft,
  FileIcon,
  ProfileHomeIcon,
  ChatIcon,
  HomeIcon,
} from "../../img/icons";

import { fetchSendbirdTokenForUser } from "../../services/sendbird/sendbird-api";
import OnboardingService, {
  OnboardingDataCallbackType,
} from "../../services/onboarding/onboarding-service";

import "@sendbird/uikit-react/dist/index.css";

import * as dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";

const imgSrc =
  "https://i0.wp.com/shoppeblack.us/wp-content/uploads/2018/08/16681499_964844290312672_7158954048931686143_n.jpg?w=80&ssl=1";

dayjs.extend(advancedFormat);

const MyPlaceholder = () => {
  return <div>Loading...</div>;
};

const MyRenderChannelListItem = (props) => {
  console.log(props);
  const foundProvider = props.channel.channel.members.find(
    (member) => member.userId.indexOf("sendbird_desk_agent") >= 0
  );
  const msgTime = props.channel.channel.lastMessage
    ? dayjs(props.channel.channel.lastMessage.createdAt).format(
        "MMM Do, h:mm A"
      )
    : "";
  return (
    <div className="channel-list-item" onClick={props.onClick}>
      <div className="channel-item-icon-provider">
        <div className="provider-image">
          <img
            title={`${foundProvider?.nickname}`}
            alt={`${foundProvider?.nickname}`}
            src={imgSrc}
          />
        </div>
      </div>
      <div className="channel-item-info">
        <div className="chat-name">{props.channel.channel._name}</div>
        <div className="chat-time">{msgTime}</div>
      </div>
      <div className="channel-item-icon-open">
        <div>
          <ChevronRight />
        </div>
      </div>
    </div>
  );
};

const shouldRenderTime = (message, messageList) => {
  const TIME_WINDOW_MILLISECONDS = 5 * 60 * 1000;

  const foundMessageIdx = messageList.findIndex(
    (msg) => msg.messageId === message.message.messageId
  );
  const prevMessageIdx = foundMessageIdx - 1;
  if (prevMessageIdx < 0) {
    return true;
  }
  const tDiff =
    message.message.createdAt - messageList[prevMessageIdx]?.createdAt;
  if (tDiff > TIME_WINDOW_MILLISECONDS) {
    return true;
  }
  return false;
};

const MyRenderChannelHeader = (props) => {
  const context = useGroupChannelContext();
  const providerNameDisplay = context.currentChannel?.members
    .map((member) => {
      if (member.userId === props.curUser) return "";
      if (member.nickname.length < 1) return "";
      return member.nickname;
    })
    .filter((name) => name.length > 0)
    .join(", ");

  return (
    <div className="chat-header">
      <div className="chat-header-left">
        <div className="chat-header-back-button" onClick={props.onBackButton}>
          <ChevronLeft />
        </div>
      </div>
      <div className="chat-header-middle">
        <div className="provider-image">
          <img
            title={`${providerNameDisplay}`}
            alt={`${providerNameDisplay}`}
            src={imgSrc}
          />
        </div>
        <div>{providerNameDisplay}</div>
      </div>
      <div className="chat-header-right"></div>
    </div>
  );
};

const MyRenderMessage = ({ message, curUser }) => {
  const context = useGroupChannelContext();
  const doTimeRender = shouldRenderTime(message, context.messages);

  // console.log('1: ', message, context.messages);

  let isFile = false;
  let fileName = "";
  const msgTime = dayjs(message.message.createdAt).format("MMM Do, h:mm A");
  let messageClass = "message-item ";
  if (message.message.sender) {
    if (curUser === message.message.sender?.userId) {
      messageClass += " message-outgoing";
    } else {
      messageClass += " message-incoming";
    }
  } else if (message.message.messageType === "admin") {
    messageClass += " message-admin";
  }
  if (message.message.messageType === "file") {
    messageClass += " message-file";
    fileName = message.message.name;
    isFile = true;
  }

  return (
    <div className="message-outer">
      {doTimeRender && <div className="message-time">{msgTime}</div>}
      <div className={messageClass}>
        <div className="message-item-inner">
          {message.message.message}
          {isFile && (
            <div className="message-file">
              <div className="message-file-icon">
                <FileIcon />
              </div>
              <div className="message-file-name">{fileName}</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

function Chat() {
  const sendbirdAppId = process.env.REACT_APP_sendbirdAppId;
  const navigate = useNavigate();

  const [curChannel, setCurrentChannel] = useState(null);
  const [curUser, setCurUser] = useState(null);
  const [authToken, setAuthToken] = useState(null);

  useEffect(() => {
    const getSendbirdToken = async (pUserId) => {
      const tokenValue = await fetchSendbirdTokenForUser(pUserId);
      setAuthToken(tokenValue);
    };

    const tOnboardingService = new OnboardingService();
    tOnboardingService.registerCallback(() => {
      const tUserId = tOnboardingService.getUserId();
      setCurUser(tUserId);
      getSendbirdToken(tUserId);
    }, OnboardingDataCallbackType.USER_DOC);
  }, [navigate]);

  const onChannelSelect = (channel) => {
    if (channel) {
      setCurrentChannel(channel._url);
    }
  };

  const clearChannel = () => {
    setCurrentChannel(null);
  };

  const redirectTo = (path) => {
    navigate(path);
  };

  useEffect(() => {}, []);

  return (
    <div className="container-fluid">
      <div id="chat" className="container chat-container">
        <div className="header-icon">
          <AstraIcon />
        </div>
        <h2>Your Messages</h2>
        <div className={`chat  ${curChannel ? "active" : "select"}`}>
          {curUser && authToken && (
            <SendbirdProvider
              appId={sendbirdAppId}
              userId={curUser}
              accessToken={authToken}
            >
              <div className="channel-list">
                <GroupChannelList
                  renderPlaceHolderLoading={() => <MyPlaceholder />}
                  channelListQueryParams={{ includeEmpty: true }}
                  onChannelSelect={onChannelSelect}
                  disableAutoSelect={true}
                  renderChannelPreview={(channel, onLeaveChannel) => (
                    <MyRenderChannelListItem channel={channel} />
                  )}
                />
              </div>
              <div className="channel-display">
                <GroupChannel
                  channelUrl={curChannel}
                  renderChannelHeader={() => {
                    return (
                      <MyRenderChannelHeader
                        curUser={curUser}
                        onBackButton={clearChannel}
                      />
                    );
                  }}
                  renderMessage={(message) => {
                    return (
                      <MyRenderMessage message={message} curUser={curUser} />
                    );
                  }}
                />
              </div>
            </SendbirdProvider>
          )}
        </div>
        <div className="mobile-nav">
          <div className="row-spaced">
            <button
              onClick={() => {
                redirectTo("/");
              }}
            >
              <HomeIcon />
              Home
            </button>
            <button className="active">
              <ChatIcon />
              Chat
            </button>
            <button
              onClick={() => {
                redirectTo("/profile");
              }}
            >
              <ProfileHomeIcon />
              Profile
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Chat;
