



export function ProfileIcon() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" >
			<path
				d="M8.16366 6.94069C8.16182 6.45322 8.3538 5.98506 8.6974 5.63926C9.04092 5.29345 9.50791 5.09845 9.99536 5.09721C10.4828 5.09596 10.9508 5.28853 11.2961 5.63256C11.6414 5.97659 11.8358 6.44381 11.8365 6.93126C11.8371 7.41873 11.6439 7.88638 11.2994 8.2313C10.955 8.57622 10.4875 8.77003 10.0001 8.77003C9.51445 8.76944 9.0488 8.57673 8.70468 8.23394C8.36065 7.89123 8.16615 7.42631 8.16365 6.94069H8.16366ZM10.0119 12.9473C8.55411 12.9401 7.11 13.2289 5.76723 13.7962C4.42445 14.3636 3.21068 15.1976 2.19945 16.2477C3.45079 17.8158 5.14525 18.9706 7.06248 19.5617C8.97951 20.1528 11.0303 20.1528 12.9473 19.5617C14.8643 18.9706 16.5588 17.8157 17.8103 16.2477C16.8006 15.1992 15.5893 14.3657 14.2491 13.7974C12.909 13.229 11.4678 12.9375 10.0121 12.9404L10.0119 12.9473ZM1.2612 14.8615C0.267777 13.0758 -0.154707 11.0286 0.0506131 8.99559C0.255858 6.96257 1.0791 5.04101 2.40933 3.48984C3.73964 1.93874 5.51318 0.832389 7.49132 0.319797C9.46946 -0.192872 11.5572 -0.087147 13.4733 0.622581C15.3895 1.33238 17.0424 2.61222 18.2092 4.28975C19.3761 5.96726 20.001 7.96201 20 10.0054C20.0023 11.7001 19.5705 13.3671 18.7459 14.8475C16.4088 12.5621 13.2693 11.2839 10.0007 11.2865C6.73199 11.2892 3.59457 12.5726 1.26113 14.8616L1.2612 14.8615ZM6.51349 6.94069C6.51474 7.86509 6.88309 8.75125 7.53748 9.40408C8.19188 10.0569 9.07879 10.4233 10.0033 10.4225C10.9277 10.4217 11.814 10.0537 12.4673 9.39959C13.1205 8.74548 13.4872 7.85874 13.4868 6.93432C13.4863 6.00984 13.1188 5.12346 12.465 4.46999C11.8112 3.8164 10.9246 3.44931 10.0001 3.44931C9.07496 3.45056 8.18814 3.81899 7.53445 4.47353C6.88071 5.12815 6.51354 6.0155 6.51354 6.94069H6.51349Z"
				fill="#083D4E"
			/>
		</svg>
	)
}
