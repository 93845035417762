import "./select-box-component.scss";

export function SelectboxComponent(props) {
  const handleChange = (event) => {
    if (props.onChange) {
      props.onChange(event.target.value);
    }
  };

  return (
    <div className="select-container">
      <select onChange={handleChange} value={props.value} label={props.label}>
        {props.options &&
          props.options.map((option, index) => {
            return (
              <option key={index} value={option.value}>
                {option.label}
              </option>
            );
          })}
      </select>
    </div>
  );
}
