import { getActiveUser, getFirebaseApp } from "../auth/firebase-user-auth";
import { doc, setDoc, getDoc, getDocs, collection } from "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import { cloneDeep } from "lodash";

import * as dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

export const OnboardingDataCallbackType = {
	USER_DOC: "USER_DOC",
	APPT_DOC: "APPT_DOC",
};

class OnboardingService {
	constructor() {
		this.step = 1;
		this.initialize();
	}

	initialize = async () => {
		this.initializeFirebase();
		this.userDoc = { onboardingStep: 1 };
		this.insuranceCredentialsDoc = {};
		this.ccDataDoc = {};
		this.apptInfoDoc = {};

		this.callbackList = [];

		await this.loadUserInformation();
		await this.loadUserOnboardingData();
	};

	getUserId = () => {
		if (! this.user) return null;
		return this.user.uid;
	}

	getUserDoc = () => {
		return this.userDoc;
	}

	initializeFirebase = () => {
		this.app = getFirebaseApp();
		this.db = getFirestore(this.app);
	};

	registerCallback = (callback, callbackType) => {
		this.callbackList.push({
			callbackFunction: callback,
			type: callbackType,
		});
	};

	notifyCallbacks = (callbackType) => {
		this.callbackList.forEach((callback) => {
			if (callback.type === callbackType) {
				callback.callbackFunction();
			}
		});
	};

	loadUserInformation = async () => {
		this.user = await getActiveUser();
	};

	setUserDoc = (doc) => {
		Object.keys(doc).forEach((key) => {
			if (key.toLowerCase().indexOf('phone') >= 0) {
				doc[key] = doc[key].replace('+1', '');
			}
		});
		this.userDoc = cloneDeep(doc);
		this.insuranceCredentialsDoc.id = this.userDoc.id;
		this.notifyCallbacks(OnboardingDataCallbackType.USER_DOC);
	};

	saveStepData = (data) => {
		Object.keys(data).forEach((key) => {
			let tObj = {};
			tObj[key] = data[key] ?? '';
			this.userDoc = Object.assign(this.userDoc, tObj);
		});
		this.notifyCallbacks(OnboardingDataCallbackType.USER_DOC);
	};

	saveInsuranceCredentialsData = (data) => {
		Object.keys(data).forEach((key) => {
			let tObj = {};
			tObj[key] = data[key];
			this.insuranceCredentialsDoc = Object.assign(
				this.insuranceCredentialsDoc,
				tObj
			);
		});
	};

	saveCCData = (data) => {
		Object.keys(data).forEach((key) => {
			let tObj = {};
			tObj[key] = data[key];
			this.ccDataDoc = Object.assign(this.ccDataDoc, tObj);
		});
	};

	saveCCDocument = async () => {
		setDoc(doc(this.db, "credit_cards", this.user.uid), this.ccDataDoc);
	};

	saveActiveDocument = async () => {
		if (this.user) {
			let saveDoc = cloneDeep(this.userDoc);
			Object.keys(saveDoc).forEach((key) => {
				if (key.toLowerCase().indexOf('phone') >= 0) {
					saveDoc[key] = '+1' + saveDoc[key];
				}
			});
	
			await setDoc(doc(this.db, "users", this.user.uid), saveDoc);
		}
	};

	saveInsuranceCredentials = async () => {
		await setDoc(
			doc(this.db, "insurance_credentials", this.user.uid),
			this.insuranceCredentialsDoc
		);
	};

	loadUserOnboardingData = async () => {
		if (this.user) {
			const docRef = doc(this.db, "users", this.user.uid);
			const apptRef = doc(this.db, "appointments", this.user.uid);
			const loadPromises = [getDoc(docRef), getDoc(apptRef)];
			const [docUser, docAppt] = await Promise.all(loadPromises);

			if (docAppt) {
				this.apptInfoDoc = docAppt.data();
				this.notifyCallbacks(OnboardingDataCallbackType.APPT_DOC);
			}

			if (docUser.exists()) {
				this.setUserDoc(docUser.data());
			} else {
				await this.createEmptyUserOnboardingData();
			}
		}
	};

	createEmptyUserOnboardingData = async () => {
		const userDoc = {
			id: this.user.uid,
			email: this.user.email,
			idEmail: this.user.email,
			onboardingStep: 1,
		};
		this.setUserDoc(userDoc);
		await this.saveActiveDocument();
	};

	getStep = () => {
		return this.userDoc.onboardingStep;
	};

	nextStep = () => {
		this.userDoc.onboardingStep++;
		return this.userDoc.onboardingStep;
	};

	setStep = (step) => {
		this.userDoc.onboardingStep = step;
		return this.userDoc.onboardingStep;
	};

	previousStep = () => {
		this.userDoc.onboardingStep--;
		return this.userDoc.onboardingStep;
	};

	loadAllTherapists = () => {
		const newTherapistPromise = new Promise((resolve, reject) => {
			getDocs(collection(this.db, "providers_v2")).then(
				(therapistQueryResults) => {
					let therapists = [];
					therapistQueryResults.forEach((doc) => {
						therapists.push(doc.data());
					});
					resolve(therapists);
					this.therapistsPromise = newTherapistPromise;
				}
			);
		});
		if (!this.therapistsPromise) {
			this.therapistsPromise = newTherapistPromise;
		}
	};

	getAllTherapists = () => {
		if (!this.therapistsPromise) {
			this.loadAllTherapists();
		}
		return this.therapistsPromise;
	};

	getAppointmentInformation = () => {
		return this.apptInfoDoc;
	};

	setAppointmentInformation = (apptInfo) => {
		if (! apptInfo.utcDate) {
			apptInfo.utcDate = dayjs(apptInfo.date + " " + apptInfo.time, "YYYY-MM-DD HH:mm").utc().format('YYYY-MM-DD HH:mm');
		}
		this.apptInfoDoc = { ...this.apptInfoDoc, ...apptInfo };
		this.notifyCallbacks(OnboardingDataCallbackType.APPT_DOC);
	};

	setAppointmentNote = (note) => {
		this.apptInfoDoc.note = note;
	};

	setAppointmentIsPaid = (isPaid) => {
		this.apptInfoDoc.isPaid = isPaid;
	}

	saveApptToFirestore = async () => {
		setDoc(doc(this.db, "appointments", this.user.uid), this.apptInfoDoc);
	}

	addAppointmentToFirestore = () => {
		return new Promise((resolve, reject) => {
			this.getAllTherapists().then((therapists) => {
				const foundTherapist = therapists.find(
					(t) => t.id === this.apptInfoDoc.providerID
				);
				const apptRecord = {
					id: this.user.uid,
					utcDate: dayjs(
						`${this.apptInfoDoc.date} ${this.apptInfoDoc.time}`,
						"YYYY-MM-DD HH:mm"
					)
						.utc()
						.format(),
					providerCredentials: foundTherapist.credentials,
					providerID: this.apptInfoDoc.providerID,
					providerImage: "",
					providerName: foundTherapist.name,
					notesForProvider: this.apptInfoDoc.note,
				};
				this.apptInfoDoc = { ...this.apptInfoDoc, ...apptRecord };
				this.saveApptToFirestore();
				resolve({
					userID: this.user.uid
					, userName: `${ this.userDoc.givenName } ${ this.userDoc.familyName }`
					, providerID: this.apptInfoDoc.providerID
					, providerName: this.apptInfoDoc.providerName
				});
			});
		});
	};

	disableContinueButton() {}
}

export default OnboardingService;
