




export function ChatIcon() {
	return (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M12.3509 0C5.93084 0 0.706618 5.22422 0.706618 11.6484C0.706618 13.6541 1.23375 15.6299 2.23233 17.3872L0.0423448 23.13C-0.0476551 23.3657 0.00805911 23.6315 0.188059 23.8114C0.312344 23.9357 0.47519 24 0.642344 24C0.719486 24 0.796629 23.9871 0.869486 23.9571L6.61233 21.7672C8.36947 22.7658 10.3451 23.2929 12.3508 23.2929C18.7751 23.2929 23.9993 18.0687 23.9993 11.6486C23.9993 5.2244 18.7751 0.000182931 12.3508 0.000182931L12.3509 0ZM11.1681 17.1042H7.49952C7.14382 17.1042 6.85666 16.8171 6.85666 16.4613C6.85666 16.1099 7.14379 15.8185 7.49952 15.8185H11.1681C11.5238 15.8185 11.8109 16.1099 11.8109 16.4613C11.8109 16.817 11.5238 17.1042 11.1681 17.1042ZM14.1896 13.3115H7.49952C7.14382 13.3115 6.85666 13.0244 6.85666 12.6687C6.85666 12.3129 7.14379 12.0258 7.49952 12.0258H14.1896C14.541 12.0258 14.8324 12.3129 14.8324 12.6687C14.8324 13.0244 14.541 13.3115 14.1896 13.3115ZM17.2067 9.51861H7.49952C7.14382 9.51861 6.85666 9.23148 6.85666 8.87575C6.85666 8.52003 7.14379 8.2329 7.49952 8.2329H17.2067C17.5624 8.2329 17.8496 8.52003 17.8496 8.87575C17.8496 9.23148 17.5624 9.51861 17.2067 9.51861Z" fill="#CAD5DD"/>
		</svg>
	)
}
