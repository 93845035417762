import "./step10.scss";

import React from "react";

export function Step10(props) {
  const handleTextChange = (e) => {
    props.onChange(e.target.value);
    props?.setHasValue((value) => ({
      ...value,
      10: !!e.target.value,
    }));
  };

  return (
    <div>
      <h2>
        Is there anything else you would like to share with your dietitian?
      </h2>
      <p className="lead">
        We use this to verify insurance and licensing information.
      </p>
      <div className="content md">
        <textarea
          onChange={handleTextChange}
          cols="60"
          rows="10"
          className="additional-notes"
          placeholder="Enter notes for your dietitian (optional)..."
        />
      </div>
    </div>
  );
}
