




export function HomeIcon() {
	return (
		<svg width="21" height="24" viewBox="0 0 21 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M20.5885 10.5835V21.3665C20.6046 22.0594 20.3407 22.7294 19.8563 23.2251C19.3719 23.7209 18.708 24.0002 18.015 24H15.4415C14.759 24 14.1044 23.7289 13.6218 23.2463C13.1391 22.7637 12.8681 22.109 12.8681 21.4265V18.8531C12.8641 18.1255 12.5525 17.4337 12.0102 16.9487C11.4763 16.4575 10.7598 16.2145 10.0372 16.2796C9.38483 16.3699 8.78847 16.6969 8.36134 17.1983C7.93431 17.6996 7.70645 18.3404 7.72111 18.9989V21.4264C7.72111 22.1089 7.44999 22.7635 6.96739 23.2462C6.48477 23.7288 5.83015 23.9999 5.14764 23.9999H2.57417C1.88112 24.0001 1.2173 23.7207 0.732894 23.225C0.248487 22.7293 -0.0154711 22.0592 0.000701724 21.3664V10.5834C-0.00126413 9.89054 0.270013 9.22474 0.755586 8.73051L8.476 0.761221C8.95924 0.274046 9.61699 0 10.3032 0C10.9893 0 11.6471 0.274046 12.1303 0.761221L19.8507 8.73051C20.33 9.22753 20.595 9.89295 20.5885 10.5834V10.5835Z" fill="#CAD5DD"/>
		</svg>
	)
}
