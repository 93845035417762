import React from "react";

import "./checkbox-component.scss";

export function CheckboxComponent(props) {
  const checkId = `checkbox-${Math.random().toString(36).substring(7)}`;

  const handleChange = (e) => {
    props.onChange(!props.checked);
  };

  return (
    <div className="checkbox-container">
      <input
        type="checkbox"
        id={checkId}
        name={checkId}
        value={props.caption}
        checked={props.checked}
        onChange={handleChange}
      />
      <label htmlFor={checkId} className={props.checked ? "checked" : ""}>
        {props.caption}
      </label>
    </div>
  );
}
