import "../../global.scss";

import React from "react";
import { useNavigate } from "react-router-dom";

import * as dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import utc from "dayjs/plugin/utc";

import {
  NewChevronLeft,
  HomeIcon,
  ChatIcon,
  ProfileHomeIcon,
} from "../../img/icons";

dayjs.extend(advancedFormat);
dayjs.extend(utc);

export function BillingInfo() {
  const navigate = useNavigate();

  return (
    <div className="container-fluid">
      <div className="page-header">
        <button className="btn btn-text" onClick={() => navigate("/profile")}>
          <NewChevronLeft />
        </button>
        <h1>Billing</h1>
        <button className="btn btn-text sm">Add</button>
      </div>
      <div className="container">
        <div className="content sm">{/* TODO: Page content here */}</div>

        <div className="mobile-nav">
          <div className="row-spaced">
            <button
              onClick={() => {
                navigate("/");
              }}
            >
              <HomeIcon />
              Home
            </button>
            <button
              onClick={() => {
                navigate("/chat");
              }}
            >
              <ChatIcon />
              Chat
            </button>
            <button
              className="active"
              onClick={() => {
                navigate("/profile");
              }}
            >
              <ProfileHomeIcon />
              Profile
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
