import { useState } from "react";
import { StateList } from "../utils/constant";

const useStateHook = () => {
  const stateOptions = StateList.map((state, idx) => {
    return { value: state, label: state };
  });
  const [selectedState, setSelectedState] = useState("");
  return [selectedState, setSelectedState, stateOptions];
};

export default useStateHook;
