import React, { useState, useEffect } from "react";
import * as dayjs from "dayjs";

import { SelectboxComponent } from "../../../../components/select-box/select-box-component";
import { TextboxComponent } from "../../../../components/textbox/textbox-component";

export function Step4({ onChange, setHasValue }) {
  const monthOptions = [
    { value: "", label: "Month" },
    { value: 1, label: " January" },
    { value: 2, label: " February" },
    { value: 3, label: " March" },
    { value: 4, label: " April" },
    { value: 5, label: " May" },
    { value: 6, label: " June" },
    { value: 7, label: " July" },
    { value: 8, label: " August" },
    { value: 9, label: " September" },
    { value: 10, label: " October" },
    { value: 11, label: " November" },
    { value: 12, label: " December" },
  ];

  const dayOptions = [
    { value: "", label: " Day" },
    ...Array.from({ length: 31 }, (_, i) => ({
      value: i + 1,
      label: `${i + 1}`,
    })),
  ];

  const yearOptions = [
    { value: "", label: " Year" }, // Placeholder option
    ...Array.from({ length: 111 }, (_, i) => {
      const year = 1910 + i;
      return { value: year, label: year };
    }),
  ];

  const [dobMonth, setDobMonth] = useState(1);
  const [dobDay, setDobDay] = useState(1);
  const [dobYear, setDobYear] = useState(yearOptions[0].value);
  const [data, setData] = useState({
    givenName: "",
    familyName: "",
  });

  useEffect(() => {
    let dobString = dayjs(`${dobMonth}/${dobDay}/${dobYear}`).format(
      "YYYY-MM-DD"
    );
    onChange({ dateOfBirth: dobString });
  }, [dobMonth, dobDay, dobYear, onChange]);

  useEffect(() => {
    setHasValue((value) => ({
      ...value,
      4: dobMonth && dobDay && dobYear && data.familyName && data.givenName,
    }));
  }, [data.familyName, data.givenName, dobDay, dobMonth, dobYear, setHasValue]);

  const handleSelectChange = (fieldSetter, value) => {
    fieldSetter(value);
  };

  const handleTextChange = (field, e) => {
    onChange({ [field]: e.target.value });
    setData({ ...data, [field]: e.target.value });
  };

  return (
    <div>
      <h2>What's your name?</h2>
      <p className="lead">Please use the same name on your insurance.</p>
      <div className="content md">
        <div className="form-row">
          <TextboxComponent
            placeholder="First Name"
            onChange={handleTextChange.bind(this, "givenName")}
          />
          <TextboxComponent
            placeholder="Last Name"
            onChange={handleTextChange.bind(this, "familyName")}
          />
        </div>
        <p className="mt-10 mb-5">What is your date of birth?</p>

        <div className="form-row dob">
          <SelectboxComponent
            options={monthOptions}
            onChange={handleSelectChange.bind(this, setDobMonth)}
          />
          <SelectboxComponent
            options={dayOptions}
            onChange={handleSelectChange.bind(this, setDobDay)}
          />
          <SelectboxComponent
            options={yearOptions}
            onChange={handleSelectChange.bind(this, setDobYear)}
          />
        </div>
      </div>
    </div>
  );
}
