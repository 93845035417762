import React, { useEffect, useState } from "react";

import { TextboxComponent } from "../../../../components/textbox/textbox-component";

export function Step5(props) {
  const [data, setData] = useState({
    email: "",
    phoneNumber: "",
  });
  const [errorMessage, setErrorMessage] = useState({
    email: "",
    phoneNumber: "",
  });

  const formatPhoneNumber = (phoneNumberString) => {
    const cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    const match = cleaned.match(
      /\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
    );
    let value = cleaned;

    if (match) {
      value = "(" + match[1] + ") " + match[2] + "-" + match?.[3];
    }
    return value;
  };

  const handleTextChange = (field, e) => {
    const value =
      field === "phoneNumber"
        ? e.target.value.replace(/\D/g, "")
        : e.target.value;

    props.onChange({ [field]: value });
    setData({
      ...data,
      [field]: field === "phoneNumber" ? formatPhoneNumber(value) : value,
    });
    if (field === "phoneNumber") {
      if (value.length === 10) {
        setErrorMessage({
          ...errorMessage,
          phoneNumber: "",
        });
      } else {
        setErrorMessage({
          ...errorMessage,
          phoneNumber: "Phone number must be 10 digits.",
        });
      }
    }
  };

  const validateEmail = () => {
    const emailRegex = new RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,4}$/i);

    setErrorMessage({
      ...errorMessage,
      email: emailRegex.test(data.email)
        ? ""
        : "Please enter a valid email address.",
    });
  };

  useEffect(() => {
    props?.setHasValue((value) => ({
      ...value,
      5:
        data.email &&
        data.phoneNumber &&
        !errorMessage.email &&
        !errorMessage.phoneNumber,
    }));
  }, [data, props?.setHasValue, errorMessage.email, errorMessage.phoneNumber]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <h2>What's your email and phone number?</h2>
      <p className="lead">Don't worry, we won't call you.</p>
      <div className="content md">
        <div className="form-row">
          <TextboxComponent
            placeholder="Enter Email"
            type="email"
            onChange={handleTextChange.bind(this, "email")}
            onFocusOut={validateEmail}
            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
            error={errorMessage.email}
            value={data.email}
          />
          <TextboxComponent
            placeholder="Enter Phone Number"
            onChange={handleTextChange.bind(this, "phoneNumber")}
            type="tel"
            error={errorMessage.phoneNumber}
            value={data.phoneNumber}
            max={10}
            pattern=".{10,10}"
          />
        </div>
      </div>
    </div>
  );
}
