import "./global.scss";

import React from "react";
import OnboardingController from "./flows/onboarding/onboarding-controller";

function App() {
  return (
    <div className="App">
      <OnboardingController />
    </div>
  );
}

export default App;
