




export function NewChevronLeft() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="9" height="16" viewBox="0 0 9 16" fill="none">
			<path d="M8.26697 1.83627L2.5884 7.50427L8.26697 13.1723C8.46976 13.3735 8.58394 13.6473 8.58394 13.933C8.58394 14.2187 8.46977 14.4925 8.26697 14.6937C8.06574 14.8965 7.79197 15.0107 7.50626 15.0107C7.22054 15.0107 6.94677 14.8965 6.74554 14.6937L0.316972 8.26513C0.11418 8.0639 0 7.79013 0 7.50441C0 7.2187 0.114175 6.94493 0.316972 6.7437L6.74554 0.315127C7.01731 0.0433645 7.4134 -0.062787 7.78472 0.0366673C8.15592 0.13611 8.446 0.426182 8.54543 0.797382C8.64487 1.1687 8.53874 1.5645 8.26697 1.83627Z" fill="#083D4E" />
		</svg>
	)
}
