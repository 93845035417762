



export function ChevronLeft() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
			<g clipPath="url(#clip0_399_10870)">
				<path d="M20 8L12 16L20 24" stroke="#083D4E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
			</g>
			<defs>
				<clipPath id="clip0_399_10870">
				<rect width="32" height="32" fill="white"/>
				</clipPath>
			</defs>
		</svg>
	)
}
