import React from "react";

import "./step-progress.scss";

export function StepProgress(props) {
  const renderStep = () => {
    let resultDivs = [];

    for (let i = 1; i <= props.stepCount; i++) {
      resultDivs.push(
        <div key={i} className="step-progress__step">
          <li className={props.curStep === i ? "step-active" : "step"}></li>
        </div>
      );
    }

    return resultDivs;
  };
  return (
    <div className="step-progress">
      <div className="step-progress__bar">{renderStep()}</div>
    </div>
  );
}
