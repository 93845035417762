import React, { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import BackIcon from "../../images/icons/left-arrow.svg";
import {
  Step1,
  Step2,
  Step3,
  Step4,
  Step5,
  Step6,
  Step7,
  Step8,
  Step9,
  Step10,
  Step11,
  Step12,
} from "./steps";
import OnboardingService, {
  OnboardingDataCallbackType,
} from "../../services/onboarding/onboarding-service";

import { createSendbirdChannelForUserAndProvider } from "../../services/sendbird/sendbird-api";

import { StepProgress } from "../../components/step-progress/step-progress";

import { AstraIcon } from "../../img/icons";

import "./onboarding-controller.scss";

const AllowedSteps = [8, 10, 12];

function OnboardingController() {
  const navigate = useNavigate();
  let [onboardingService, setOnboardingService] = useState(null);
  let [currentStep, setCurrentStep] = useState(1);
  const [stepTransitionAllowed, setStepTransitionAllowed] = useState(true);
  const [hasData, setHasData] = useState({});
  const [apptInfo, setApptInfo] = useState({});

  const STEP_TRANSITIONS = {
    1: 2,
    2: 3,
    3: 4,
    4: 5,
    5: 7,
    7: 8,
    8: 9,
    9: 10,
    10: 12,
    12: "/",
  };

  useEffect(() => {
    const tOnboardingService = new OnboardingService();
    setOnboardingService(tOnboardingService);
    setCurrentStep(tOnboardingService.getStep());

    tOnboardingService.registerCallback(() => {
      setCurrentStep(tOnboardingService.getStep());
    }, OnboardingDataCallbackType.USER_DOC);

    tOnboardingService.registerCallback(() => {
      setApptInfo(tOnboardingService.getAppointmentInformation());
    }, OnboardingDataCallbackType.APPT_DOC);
  }, []);

  const getContinueButtonCaption = () => {
    if (currentStep <= 8) return "Continue";
    if (currentStep < 12) return "Confirm Appointment";
    return "Complete";
  };

  const handleStepDataChange = (data) => {
    onboardingService.saveStepData(data);
  };

  const handleStepDataChangeInsuranceCredentials = (data) => {
    onboardingService.saveInsuranceCredentialsData(data);
  };

  const handlesetStepTransitionAllowed = (allowed) => {
    setStepTransitionAllowed(allowed);
  };

  const handleAppointmentDataChange = (data) => {
    onboardingService.setAppointmentInformation(data);
  };

  const handleAppointmentNoteChange = (note) => {
    onboardingService.setAppointmentNote(note ?? "");
  };

  const handleCCDataChange = (data) => {
    onboardingService.saveCCData(data);
  };

  const renderStep = () => {
    const stepComponents = [
      null,
      <Step1 onChange={handleStepDataChange} setHasValue={setHasData} />,
      <Step2 onChange={handleStepDataChange} setHasValue={setHasData} />,
      <Step3
        onChange={handleStepDataChangeInsuranceCredentials}
        setHasValue={setHasData}
      />,
      <Step4 onChange={handleStepDataChange} setHasValue={setHasData} />,
      <Step5 onChange={handleStepDataChange} setHasValue={setHasData} />,
      <Step6 onChange={handleStepDataChange} setHasValue={setHasData} />,
      <Step7 onChange={handleStepDataChange} setHasValue={setHasData} />,
      <Step8 />,
      <Step9
        setHasValue={setHasData}
        onChange={handleAppointmentDataChange}
        onboardingService={onboardingService}
        setStepTransitionAllowed={handlesetStepTransitionAllowed}
      />,
      <Step10
        onChange={handleAppointmentNoteChange}
        setHasValue={setHasData}
      />,
      <Step11 onChange={handleCCDataChange} setHasValue={setHasData} />,
      <Step12 apptInfo={apptInfo} />,
    ];
    if (!onboardingService) return <div></div>;

    const curStep = onboardingService.getStep();
    if (curStep < 1 || curStep > stepComponents.length) return <div></div>;
    return stepComponents[curStep];
  };

  const isContinueDisabled = useMemo(() => {
    if (AllowedSteps.includes(currentStep)) return false;

    return !hasData[currentStep];
  }, [hasData, currentStep]);

  const nextStep = async () => {
    if (!stepTransitionAllowed) {
      alert("Please complete the current step before continuing");
      return;
    }
    if (!onboardingService) return;
    const newStep = STEP_TRANSITIONS[currentStep];
    if (typeof newStep === "string") {
      navigate(newStep);
      return;
    }
    onboardingService.setStep(newStep);

    // don't save the appointment step until we have notes...
    // so if the user bails, we still come back to 9...
    switch (currentStep) {
      case 10:
        onboardingService.addAppointmentToFirestore().then((apptInfo) => {
          createSendbirdChannelForUserAndProvider(apptInfo);
        });
        break;
      case 3:
        onboardingService.saveInsuranceCredentials();
        break;
      case 11:
        onboardingService.saveCCDocument();
        break;
      case 12:
        navigate("/");
        break;
      default:
        break;
    }
    setCurrentStep(newStep);
    if (currentStep !== 9) {
      onboardingService.saveActiveDocument();
    }
  };

  const previousStep = () => {
    if (!onboardingService) return;
    let newStep = onboardingService.previousStep();
    onboardingService.saveActiveDocument();
    setCurrentStep(newStep);
  };

  return (
    <div className="container-fluid">
      <h1 className="sr-only">Onboarding</h1>
      <div className="container">
        <div className="progress-bar">
          {currentStep > 1 && (
            <div
              onClick={previousStep}
              className="back-button btn btn-text icon-left"
            >
              <img src={BackIcon} alt="back-icon" className="icon" />
              Back
            </div>
          )}
          <div className="header-icon">
            <AstraIcon />
          </div>
          <StepProgress
            stepCount={Object.keys(STEP_TRANSITIONS).length}
            curStep={
              Object.keys(STEP_TRANSITIONS).indexOf(currentStep.toString()) + 1
            }
          />
        </div>
        <div className="step-container">{renderStep()}</div>
        <div className="button-footer">
          <button
            className={`btn btn-primary ${isContinueDisabled && "disabled"}`}
            disabled={isContinueDisabled}
            onClick={() => nextStep()}
          >
            {getContinueButtonCaption()}
          </button>
        </div>
      </div>
    </div>
  );
}

export default OnboardingController;
