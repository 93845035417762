import "./step1.scss";

import React, { useState } from "react";
import { CheckboxComponent } from "../../../../components/checkbox/checkbox-component";

export function Step1(props) {
  const defineItems = [
    { selected: false, name: "Improve my general health" },
    { selected: false, name: "Diabetes or Pre-Diabetes" },
    { selected: false, name: "Improve cholesterol or heart health" },
    {
      selected: false,
      name: "Establish healthy habits during or post-ozempic",
    },
    { selected: false, name: "Emotional eating" },
    { selected: false, name: "Lose weight" },
    { selected: false, name: "Gain weight" },
    { selected: false, name: "Improve gut health" },
    { selected: false, name: "Women's/Reproductive health" },
    { selected: false, name: "Pre/Post natal" },
    { selected: false, name: "Athletic performance" },
    { selected: false, name: "Food allergies/sensitivities" },
    { selected: false, name: "Vegan/Vegetarian" },
    { selected: false, name: "Cancer" },
    { selected: false, name: "Other" },
  ];

  const [checkboxItems, setCheckboxItems] = useState(defineItems);
  const [otherMessage, setOtherMessage] = useState("");

  const handleChange = (selected, changedItem) => {
    const items = [...checkboxItems];
    const foundItem = items.find((item) => item.name === changedItem.name);

    if (foundItem) foundItem.selected = selected;
    setCheckboxItems([...items]);

    const selectedItems = checkboxItems
      .filter((item) => item.selected)
      .map((item) => item.name);

    props.onChange({ reasons: selectedItems });
    props.setHasValue((value) => ({ ...value, 1: !!selectedItems.length }));
  };

  const handleOtherChange = (event) => {
    setOtherMessage(event.target.value);
  };

  const renderCheckbox = (checkItem) => {
    return (
      <div className="checkbox-list__item" key={checkItem.name}>
        <CheckboxComponent
          caption={checkItem.name}
          checked={checkItem.selected}
          onChange={(value) => handleChange(value, checkItem)}
        />

        {checkItem.selected && checkItem.name === "Other" && (
          <textarea
            rows="10"
            type="text"
            placeholder="Please explain."
            value={otherMessage}
            onChange={handleOtherChange}
          />
        )}
      </div>
    );
  };

  return (
    <>
      <h2>Why do you want to improve your nutrition?</h2>
      <p className="lead">Select all that apply.</p>
      <div className="content sm">
        <div className="checkbox-list">{checkboxItems.map(renderCheckbox)}</div>
      </div>
    </>
  );
}
