import "../../global.scss";
import "./profile-common.scss";

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import * as dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import utc from "dayjs/plugin/utc";

import OnboardingService, {
  OnboardingDataCallbackType,
} from "../../services/onboarding/onboarding-service";
import { LabelTextField } from "../../components/label-text-combo-field/label-text-combo-field";

import {
  NewChevronLeft,
  HomeIcon,
  ChatIcon,
  ProfileHomeIcon,
} from "../../img/icons";

dayjs.extend(advancedFormat);
dayjs.extend(utc);

export function ContactInfo() {
  const navigate = useNavigate();
  const [editMode, setEditMode] = useState(false);
  const [onboardingService, setOnboardingService] = useState(null);

  const [userData, setUserData] = useState({
    email: "",
    phoneNumber: "",
    emergencyName: "",
    emergencyPhone: "",
  });

  useEffect(() => {
    const tOnboardingService = new OnboardingService();
    setOnboardingService(tOnboardingService);

    tOnboardingService.registerCallback(() => {
      const userDoc = tOnboardingService.getUserDoc();
      const newUserData = { ...userData };
      Object.keys(userData).forEach((key) => {
        newUserData[key] = userDoc[key] ?? "";
      });
      setUserData(newUserData);
    }, OnboardingDataCallbackType.USER_DOC);
  }, [navigate]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleFieldChange = (fieldId, value) => {
    const newUserData = { ...userData };
    newUserData[fieldId] = value;

    setUserData(newUserData);
  };

  const getFieldValue = (fieldId, fieldFormat) => {
    let fieldValue = userData[fieldId];

    return fieldValue;
  };

  const toggleEditMode = async () => {
    const newEditMode = !editMode;
    setEditMode(newEditMode);
    if (!newEditMode) {
      onboardingService.saveStepData(userData);
      await onboardingService.saveActiveDocument();
    }
  };

  return (
    <div className="container-fluid">
      <div className="page-header">
        <button className="btn btn-text" onClick={() => navigate("/profile")}>
          <NewChevronLeft />
        </button>
        <h1>Contact Info</h1>
        <button className="btn btn-text sm" onClick={toggleEditMode}>
          {editMode ? "Done" : "Edit"}
        </button>
      </div>
      <div className="container">
        <div className="content sm">
          <LabelTextField
            isediting={editMode}
            label="Email"
            fieldid="email"
            onChange={handleFieldChange}
            getfieldvalue={getFieldValue}
          />
          <LabelTextField
            isediting={editMode}
            label="Phone Number"
            fieldid="phoneNumber"
            fieldtype="tel"
            onChange={handleFieldChange}
            getfieldvalue={getFieldValue}
          />
          <h6 className="text-center mt-10 mb-5">Emergency Contact</h6>
          <LabelTextField
            isediting={editMode}
            label="Name"
            fieldid="emergencyName"
            onChange={handleFieldChange}
            getfieldvalue={getFieldValue}
          />
          <LabelTextField
            isediting={editMode}
            label="Phone Number"
            fieldid="emergencyPhone"
            fieldtype="tel"
            onChange={handleFieldChange}
            getfieldvalue={getFieldValue}
          />
        </div>

        <div className="mobile-nav">
          <div className="row-spaced">
            <button
              onClick={() => {
                navigate("/");
              }}
            >
              <HomeIcon />
              Home
            </button>
            <button
              onClick={() => {
                navigate("/chat");
              }}
            >
              <ChatIcon />
              Chat
            </button>
            <button
              className="active"
              onClick={() => {
                navigate("/profile");
              }}
            >
              <ProfileHomeIcon />
              Profile
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
