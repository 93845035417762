import "../../global.scss";

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import * as dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import utc from "dayjs/plugin/utc";

import OnboardingService, {
  OnboardingDataCallbackType,
} from "../../services/onboarding/onboarding-service";

import {
  PersonalInfoIcon,
  ContactInfoIcon,
  ChevronRight,
  HomeIcon,
  ChatIcon,
  ProfileHomeIcon,
} from "../../img/icons";

dayjs.extend(advancedFormat);
dayjs.extend(utc);

export function Profile() {
  const navigate = useNavigate();

  const [userData, setUserData] = useState({
    givenName: "",
    familyName: "",
  });

  const [userInitials, setUserInitials] = useState("");

  useEffect(() => {
    const tOnboardingService = new OnboardingService();

    tOnboardingService.registerCallback(() => {
      const userDoc = tOnboardingService.getUserDoc();
      const newUserData = { ...userData };
      Object.keys(userData).forEach((key) => {
        newUserData[key] = userDoc[key] ?? "";
      });
      setUserData(newUserData);

      const firstInitial = newUserData.givenName[0];
      const lastInitial = newUserData.familyName[0];
      setUserInitials(`${firstInitial}${lastInitial}`);
    }, OnboardingDataCallbackType.USER_DOC);
  }, [navigate]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="container-fluid">
      <div id="profile" className="container">
        <div className="header-icon">
          <div className="user-avatar">{userInitials}</div>
        </div>
        <h2 className="mb-10">
          {userData.givenName} {userData.familyName}
        </h2>
        <div className="content sm">
          <div className="dark-block mb-5">
            <h6 className="text-center offset-top">My Account</h6>
            {/* Hidden for now */}
            {/* <button
              className="btn btn-menu-item w-100 icon-left text-left mb-3"
              onClick={() => {
                navigate("/profile/notifications");
              }}
            >
              <div>
                <span className="icon TODO">todo</span>
                <span className="text">Notifications</span>
              </div>
              <ChevronRight />
            </button> */}
            <button
              className="btn btn-menu-item w-100 icon-left text-left mb-3"
              onClick={() => {
                navigate("/profile/personal-info");
              }}
            >
              <div>
                <span className="icon">
                  <PersonalInfoIcon />
                </span>
                <span className="text">Personal Info</span>
              </div>
              <ChevronRight />
            </button>
            <button
              className="btn btn-menu-item w-100 icon-left text-left mb-3"
              onClick={() => {
                navigate("/profile/contact-info");
              }}
            >
              <div>
                <span className="icon">
                  <ContactInfoIcon />
                </span>
                <span className="text">Contact Info</span>
              </div>
              <ChevronRight />
            </button>
            {/* Hidden for now */}
            {/* <button
              className="btn btn-menu-item w-100 icon-left text-left mb-3"
              onClick={() => {
                navigate("/profile/health-insurance");
              }}
            >
              <div>
                <span className="icon TODO">todo</span>
                <span className="text">Health Insurance</span>
              </div>
              <ChevronRight />
            </button> */}
          </div>

          {/* <div className="dark-block mb-5"> */}
          {/* <h6 className="text-center offset-top">Management</h6> */}
          {/* Hidden for now */}
          {/* <button
              className="btn btn-menu-item w-100 icon-left text-left mb-3"
              onClick={() => {
                navigate("/profile/documents");
              }}
            >
              <div>
                <span className="icon TODO">todo</span>
                <span className="text">Documents</span>
              </div>
              <div>
                <div className="button-meta me-5">4/4</div>
                <ChevronRight />
              </div>
            </button> */}
          {/* Hidden for now */}
          {/* <button
              className="btn btn-menu-item w-100 icon-left text-left mb-3"
              onClick={() => {
                navigate("/profile/billing-info");
              }}
            >
              <div>
                <span className="icon">
                  <BillingIcon />
                </span>
                <span className="text">Billing</span>
              </div>
              <ChevronRight />
            </button> */}
          {/* </div> */}
          {/* TODO: Hook up to logout */}
          {/* <button className="btn btn-primary w-100">Log Out</button> */}
        </div>

        <div className="mobile-nav">
          <div className="row-spaced">
            <button
              onClick={() => {
                navigate("/");
              }}
            >
              <HomeIcon />
              Home
            </button>
            <button
              onClick={() => {
                navigate("/chat");
              }}
            >
              <ChatIcon />
              Chat
            </button>
            <button
              className="active"
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              <ProfileHomeIcon />
              Profile
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
