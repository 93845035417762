import './button-component.scss';

export function ButtonComponent(props) {

	const handleClick = () => {
		props.handleClick(props.text);
	};

	return (
		<div className="button-container">
			<button onClick={ handleClick } className={ `button ${ props.groupSelected === props.text ? 'button-active' : '' }` } >{props.text}</button>
		</div>
	)
}
