import "./step9.scss";

import React, { useEffect, useState } from "react";

import * as dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";

import { CalendarComponent } from "../../../../components/calendar/calendar-component";
import { TherapistComponent } from "../../../../components/therapist/therapist-component";

dayjs.extend(isSameOrAfter);

export function Step9(props) {
	const [availableDates, setAvailableDates] = useState(null);
	const [therapists, setTherapists] = useState([]);
	const [selectedDate, setSelectedDate] = useState(null);
	const [activeTimeId, setActiveTimeId] = useState(null);

	useEffect(() => {
		props.setStepTransitionAllowed(false);

		// const schedulePromise = props.onboardingService.getAllSchedules();
		const therapistPromise = props.onboardingService.getAllTherapists();
		Promise.all([therapistPromise]).then((results) => {
			const [therapists] = results;
			const therapistLookup = new Map();
			const allDates = new Map();
			therapists.forEach((t) => {
				therapistLookup.set(t.id, t);
				let dateLookup = new Map();
				t.schedule.forEach((ai) => {
					const td = dayjs(ai);
					const tLookup = td.format("YYYY-MM-DD");
					allDates.set(tLookup, true);
					if (!dateLookup.has(tLookup)) {
						dateLookup.set(tLookup, []);
					}
					dateLookup.get(tLookup).push(td.format("HH:mm"));
				});
				t.availability = {};
				dateLookup.forEach((times, date) => {
					t.availability[date] = times;
				});
			});
			const todayCheck = dayjs().hour(0).minute(0).second(0);
			const dateToSelect = Array.from(allDates.keys())
				.filter((di) => dayjs(di).isSameOrAfter(todayCheck, "day"))
				.sort()[0];
			setSelectedDate(dateToSelect);
			setAvailableDates(allDates);
			setTherapists(therapists);
		});
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const handleDateChange = (date) => {
		setSelectedDate(date.format("YYYY-MM-DD"));
		props.setStepTransitionAllowed(false);
		setActiveTimeId("");
	};

	const onTimeSelected = (timeSelectedInformation) => {
		setActiveTimeId(timeSelectedInformation.selectedTimeId);
		props.setStepTransitionAllowed(true);
		props.onChange(timeSelectedInformation);
		props?.setHasValue((value) => ({
			...value,
			9: !!timeSelectedInformation.selectedTimeId,
		}));
	};

	return (
		<>
			<h2>Let's schedule your first 55-minute session...</h2>
			<div id="step9" className="content lg">
				<div className="step-calendar-container">
					<CalendarComponent
						activeDates={availableDates}
						onDateChange={handleDateChange}
						selectedDate={selectedDate}
					/>
				</div>
				<div className="filter-row mt-10">
					<p>Available Dietitians ({therapists.length})</p>
					<p>Afternoon</p>
				</div>
				{therapists
					.filter((tf) => tf.availability[selectedDate])
					.map((t, idx) => {
						return (
							<div key={idx}>
								<TherapistComponent
									selectedDate={selectedDate}
									therapist={t}
									onTimeSelected={onTimeSelected}
									activeTime={activeTimeId}
								/>
							</div>
						);
					})}
			</div>
		</>
	);
}
