import "./therapist-component.scss";
import * as dayjs from "dayjs";

function TherapistSpecialty(props) {
	return <div className="pill">{props.specialty}</div>;
}

export function TherapistImageNameCreds(props) {
	return (
		<div className="therapist-bio">
			<div className="therapist-bio__intro">
				<img
					className="avatar"
					alt={`${ props.therapist.name || props.therapist.providerName }`}
					src={ props.therapist.photoURL }
				/>
				<div className="details">
					<h5 className="name">
						{ props.therapist.name || props.therapist.providerName }
					</h5>
					<p className="credentials">
						{ props.therapist.credentials || props.therapist.providerCredentials }
					</p>
				</div>
			</div>
		</div>
	);
}

export function TherapistComponent(props) {
	const getTimeId = (time) => {
		return `${ props.therapist.id }-${ time }-${ props.selectedDate }`;
	};

	const timeClicked = (event, timeValue) => {
		event.preventDefault();
		const timeClickedMessage = {
			providerID: props.therapist.id,
			time: timeValue,
			selectedTimeId: getTimeId(timeValue),
			date: props.selectedDate,
		};
		props.onTimeSelected(timeClickedMessage);
	};

	const renderTherapistTime = (time, idx) => {
		const extraClass = getTimeId(time) === props.activeTime ? "selected" : "";
		return (
			<button
				className={`time-option ${extraClass}`}
				key={idx}
				onClick={(event) => timeClicked(event, time)}
			>
				{dayjs(`12-01-2000 ${time}`).format("hh:mm A")}
			</button>
		);
	};

	return (
		<div className="dark-block split my-3">
			<div>
				<TherapistImageNameCreds therapist={props.therapist} />
				<h6>Specialties</h6>
				<div className="pill-container">
					{props.therapist.specialities?.map((s, idx) => {
						return <TherapistSpecialty key={`spec${idx}`} specialty={s} />;
					})}
				</div>
			</div>
			<div>
				<h6>Availability</h6>
				<div className="availability-container">
					{props.therapist.availability[props.selectedDate]
						.sort()
						.map(renderTherapistTime)}
				</div>
			</div>
		</div>
	);
}
