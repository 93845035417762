import React from 'react';
import ReactDOM from 'react-dom/client';
import {
	createBrowserRouter,
	RouterProvider,
} from "react-router-dom";
import './index.css';

import App from './App';
import Chat from './flows/chat/chat';
import Home from './flows/home/home';
import Login from './flows/login/login';
import { Profile, PersonalInfo, ContactInfo, BillingInfo } from './flows/profile';
import CreditCardForm from './flows/credit-card/credit-card-form';

import reportWebVitals from './reportWebVitals';
import { RecoilRoot } from 'recoil';

import { authenticateGoogleUser } from "./services/auth/firebase-user-auth";



const router = createBrowserRouter([
	{
		path: "/onboarding",
		element: <App />
	},
	{
		path: "/chat",
		element: <Chat />
	},
	{
		path: "/",
		element: <Home />
	},
	{
		path: "/home",
		element: <Home />
	},
	{
		path: "/login",
		element: <Login />
	},
	{
		path: "/payment",
		element: <CreditCardForm />
	},
	{
		path: "/profile",
		element: <Profile />
	},
	{
		path: "/profile/personal-info",
		element: <PersonalInfo />
	},
	{
		path: "/profile/contact-info",
		element: <ContactInfo />
	},
	{
		path: "/profile/billing-info",
		element: <BillingInfo />
	},
]);

async function beginAuthentication() {
	authenticateGoogleUser()
	.then((authUser) => {
		if (authUser === null) {
			router.navigate('/login');
			return;
		}
	})
}
beginAuthentication();



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<RecoilRoot>
		<RouterProvider router={router} />
	</RecoilRoot>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
