import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";

import OnboardingService, { OnboardingDataCallbackType } from "../../services/onboarding/onboarding-service";
import { v4 as uuidv4 }  from 'uuid';
import './credit-card-form.scss';

function AddLibrary(urlOfTheLibrary) {
	const script = document.createElement("script");
	script.src = urlOfTheLibrary;
	script.async = true;
	document.body.appendChild(script);
}

const BASE_CC_URL = `https://${ process.env.REACT_APP_squareURL}`;
const STORE_CARD_URL = `${ BASE_CC_URL }/save-card`;
const LIST_CARDS_URL = `${ BASE_CC_URL }/list-cards`;
const CREATE_CREDIT_CARD_CUSTOMER_URL = `${ BASE_CC_URL }/create-customer`;
const PROCESS_PAYMENT_URL = `${ BASE_CC_URL }/process-payment`;
const COMPLETE_PAYMENT_URL = `${ BASE_CC_URL }/complete-payment`;


function CreditCardTest() {

	const navigate = useNavigate();

	const APPLICATION_ID = process.env.REACT_APP_squareAppId;
	const LOCATION_ID = process.env.REACT_APP_squareLocationId;

	const [ isLoaded, setIsLoaded ] = useState(false);
	const [ initializeComplete, setInitializeComplete ] = useState(false);
	const [ cardObject, setCardObject ] = useState(null);
	const [ savedCardsArray, setSavedCardsArray ] = useState([]);
	const [ userData, setUserData ] = useState(null);
	const [ saveCard, setSaveCard ] = useState(false);
	const [ onboardingService, setOnboardingService ] = useState(null);
	const [ selectedCard, setSelectedCard ] = useState('0');

	useEffect(() => {
		const loadUserDoc = async () => {
			const tUserDoc = await tOnboardingService.getUserDoc();
			setUserData(tUserDoc);
			if (tUserDoc.squareCustomerId) {
				const tCards = await listUserCreditCards(tUserDoc.squareCustomerId);
				setSavedCardsArray(tCards.cards);
			}
		}

		const tOnboardingService = new OnboardingService();
		setOnboardingService(tOnboardingService);
		tOnboardingService.registerCallback(() => {
			loadUserDoc();
		}, OnboardingDataCallbackType.USER_DOC);

		tOnboardingService.registerCallback(() => {
			if (tOnboardingService.apptInfoDoc.isPaid) {
				navigate('/home');
			}
		}, OnboardingDataCallbackType.APPT_DOC);
	}, [ navigate ]);

	const tokenize = async (paymentMethod) => {
		const tokenResult = await paymentMethod.tokenize();
		if (tokenResult.status === 'OK') {
			return tokenResult.token;
		} else {
			throw new Error(tokenResult.errors[0].message);
		}
	}

	const createOrFetchCustomerId = async () => {
		if (userData.squareCustomerId) {
			return userData.squareCustomerId;
		}
		const newCustomerId = await createCreditCardCustomer();
		onboardingService.saveStepData({ squareCustomerId: newCustomerId });
		await onboardingService.saveActiveDocument();
	}

	const createCreditCardCustomer = async () => {
		try {
			const createCustomerPayload = {
				given_name: userData.givenName
				, family_name: userData.familyName
				, emailAddress: userData.email
				, address: {
					addressLine1: userData.lineOne
					, addressLine2: userData.lineTwo
					, locality: userData.city
					, administrativeDistrictLevel1: userData.state
					, postalCode: userData.zip
					, country: "US"
				}
				, phoneNumber: userData.phoneNumer
				, referenceId: "tim-1394713"
				, note: ""
			};

			const newCustomerData = await fetch(CREATE_CREDIT_CARD_CUSTOMER_URL, {
				method: 'POST'
				, headers: {
					'Content-Type': 'application/json',
				}
				, body: JSON.stringify(createCustomerPayload)
			});

			const newCusomterObject = await newCustomerData.json();
			return newCusomterObject.id;
		}
		catch (e) {
			console.error('Create Customer Failure', e);
			throw new Error('Create Customer Failure');
		}
	}

	const processPayment = async (token, customerId, idempotencyKey) => {
		const paymentUrl = `${ PROCESS_PAYMENT_URL }?transactionAmount=99&customerID=${ customerId }`;
		const paymentPayload = {
			token
			, idempotencyKey
		};

		const paymentResponseData = await fetch(paymentUrl, {
			method: 'POST'
			, headers: {
				'Content-Type': 'application/json',
			}
			, body: JSON.stringify(paymentPayload)
		});

		const paymentResponseObject = await paymentResponseData.json();
		return paymentResponseObject.id;
	}

	const completePayment = async (token, paymentId, idempotencyKey) => {
		const completePaymentUrl = `${ COMPLETE_PAYMENT_URL }?paymentID=${ paymentId }`;
		const completePaymentPayload = {
			token
			, idempotencyKey
		};

		const completePaymentResponseData = await fetch(completePaymentUrl, {
			method: 'POST'
			, headers: {
				'Content-Type': 'application/json',
			}
			, body: JSON.stringify(completePaymentPayload)
		});

		const completePaymentResponseObject = await completePaymentResponseData.json();
		return completePaymentResponseObject.message;
	}

	const saveUserCreditCard = async (cardholderId, token) => {
		const ccSavePayload = {
			token
			, idempotencyKey: uuidv4()
			, address: {
				street: userData.lineOne
				, city: userData.city
				, state: userData.state
				, country: "US"
			}
			, cardholderName: `${userData.givenName} ${userData.familyName}`
			, cardholderId
			, referenceId: uuidv4()
		};

		try {
			const saveCardResultData = await fetch(STORE_CARD_URL, {
				method: 'POST'
				, headers: {
					'Content-Type': 'application/json',
				}
				, body: JSON.stringify(ccSavePayload)
			});

			const saveCardResultObject = await saveCardResultData.json();
			return saveCardResultObject.message === 'SUCCESS';
		}
		catch (e) {
			console.log('Save Card Failure', e);
			return false;
		}
	}

	const listUserCreditCards = async (customerId) => {
		const listCardsUrl = `${ LIST_CARDS_URL }?customer_id=${ customerId }`;

		const listCardsData = await fetch(listCardsUrl, {
			method: 'GET'
			, headers: {
				'Content-Type': 'application/json',
			}
		});

		const listCardsObject = await listCardsData.json();
		return listCardsObject;
	}

	const reliablyListUserCreditCardsAndGetToken = async (customerId) => {
		let RETRY_COUNT = 3;

		while (RETRY_COUNT > 0) {
			try {
				const cardData = await listUserCreditCards(customerId);
				if (cardData?.cards?.length > 0) {
					return cardData.cards[0].id;
				}
			}
			catch (e) {
				console.error('List Cards Failure', e);
			}
			RETRY_COUNT--;
		}
		return '';
	}
		


	const handleCompletePaymentClick = async (event) => {
		try {
			let token = selectedCard;
			let customerId = userData.squareCustomerId;

			if (token === '0') {
				token = await tokenize(cardObject);
				customerId = await createOrFetchCustomerId();

				if (saveCard) {
					await saveUserCreditCard(customerId, token);
					const cardToChargeToken = await reliablyListUserCreditCardsAndGetToken(customerId);
					if (cardToChargeToken) {
						token = cardToChargeToken;
						console.log('new charge token', token);
					}
				}
			}

			const idempotencyKey = uuidv4();
			const paymentId = await processPayment(token, customerId, idempotencyKey);
			const completePaymentMessage = await completePayment(token, paymentId, idempotencyKey);
			if (completePaymentMessage === 'SUCCESS') {
				onboardingService.setAppointmentIsPaid(true);
				await onboardingService.saveApptToFirestore();
			}

			navigate('/home');
		} catch (e) {
			alert(e);
			console.error('Store Card Failure', e);
		}
	}

	const doSquareInit = () => {
		async function initializeCard(payments) {
			const card = await payments.card();
			setCardObject(card);
			await card.attach('#card-form');
	
			return card;
		}

		const doInit = async (pPayments) => {
			return await initializeCard(pPayments);
		};

		if (window.Square && (! initializeComplete)) {
			let payments;
			try {
				payments = window.Square.payments(APPLICATION_ID, LOCATION_ID);
			} catch(e) {
				console.error('Initializing payments failed', e);
			}

			try {
				doInit(payments);
			} catch (e) {
				console.error('Initializing Card failed', e);
			}
			setInitializeComplete(true);
		}
	}

	useEffect(() => {
		if (isLoaded) {
			doSquareInit();
		}
	}, [ isLoaded ]);	// eslint-disable-line react-hooks/exhaustive-deps

	const squareLoadCheckIntervalId = setInterval(() => {
		if (window.Square) {
			clearInterval(squareLoadCheckIntervalId);
			setIsLoaded(true);
		}
	}, 100);

	const formattedMonth = (pNumber) => {
		return pNumber.toLocaleString('en-US', {
			minimumIntegerDigits: 2,
			useGrouping: false
		});
	}

	const renderSavedCard = (card) => {
		return (
			<div key={ card.id } className="saved-card">
				<div className="saved-card-select">
					<input type="radio" name="saved-card" value={ card.id } onChange={ onCardChanged } />
				</div>
				<div className="saved-card-number">
					Card ending in { card.last4 }
				</div>
				<div className="saved-card-expire">
					Expires { `${ formattedMonth(card.expMonth) }/${ card.expYear }` }
				</div>
			</div>
		)
	};

	const onCardChanged = (event) => {
		const cardId = event.target.value;
		setSelectedCard(cardId);
	}

	
	const visStyleObj = { visibility: ((selectedCard === '0' || savedCardsArray.length < 1) ? 'visible' : 'hidden') };

	return (
		<div className="cc-form-outer">
			<div className="text-medium">
				To confirm your booking we need a credit card payment.
			</div>
			{
				(savedCardsArray.length > 0) && (
					<div className="saved-cards-container">
						<div className="saved-cards-label">
							Use an existing card
						</div>
						<div className="saved-cards">
							{
								savedCardsArray.map(renderSavedCard)
							}
							<div key={ 'new-card' } className="saved-card">
								<div className="saved-card-select">
									<input type="radio" name="saved-card" value={ 0 } onChange={ onCardChanged } />
								</div>
								<div className="saved-card-number">
									Enter a new card
								</div>
								<div className="saved-card-expire">
									
								</div>
							</div>
						</div>
					</div>
				)
			}
			<div className="card-form" id="card-form" style={ visStyleObj }>
			</div>
			<div className="save-card-container text-medium" style={ visStyleObj }>
				<input type="checkbox" className="save-card" id="save-card" value={ saveCard } onChange={ () => { setSaveCard(! saveCard) } } />
				<label htmlFor="save-card">Save this card for future payments</label>
			</div>
			<div className="card-form-button">
				<button onClick={ handleCompletePaymentClick } id="card-button" type="button">Complete Payment</button>
			</div>
			<div className="text-small" style={{ marginBottom: '40px' }}>
				Payment information will be processed securely.
			</div>
			<div className="text-small">
				Your credit card will only be charged according to our billing policy. Your
				details are transmitted over an encrypted and secure connection.
			</div>
			{ AddLibrary("https://sandbox.web.squarecdn.com/v1/square.js") }
		</div>
	  )
}

export default CreditCardTest;
