





export function FileIcon() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="20" height="24" viewBox="0 0 20 24" fill="none">
			<path d="M18.6703 4.21802L15.2043 0.752095C14.7139 0.261634 14.0599 0 13.406 0H2.55045C1.14441 0 0 1.14441 0 2.55045V21.4495C0 22.8556 1.14441 24 2.55045 24H16.8719C18.278 24 19.4224 22.8556 19.4224 21.4495L19.4223 6.01638C19.4223 5.32976 19.1606 4.70848 18.6702 4.21802H18.6703ZM5.03545 19.4877H4.80662C4.15267 19.4877 3.62954 18.9646 3.62954 18.3106C3.62954 17.6567 4.15267 17.1335 4.80662 17.1335H5.03545C5.6894 17.1335 6.21253 17.6567 6.21253 18.3106C6.21253 18.9646 5.65673 19.4877 5.03545 19.4877ZM5.03545 14.3869H4.80662C4.15267 14.3869 3.62954 13.8638 3.62954 13.2098C3.62954 12.5559 4.15267 12.0328 4.80662 12.0328H5.03545C5.6894 12.0328 6.21253 12.5559 6.21253 13.2098C6.21253 13.8638 5.65673 14.3869 5.03545 14.3869ZM5.03545 9.28612H4.80662C4.15267 9.28612 3.62954 8.76299 3.62954 8.10904C3.62954 7.45509 4.15267 6.93196 4.80662 6.93196H5.03545C5.6894 6.93196 6.21253 7.45509 6.21253 8.10904C6.21253 8.76299 5.65673 9.28612 5.03545 9.28612ZM14.6158 19.4877H8.89375C8.2398 19.4877 7.71667 18.9646 7.71667 18.3106C7.71667 17.6567 8.2398 17.1335 8.89375 17.1335H14.6158C15.2697 17.1335 15.7929 17.6567 15.7929 18.3106C15.7929 18.9646 15.2697 19.4877 14.6158 19.4877ZM14.6158 14.3869H8.89375C8.2398 14.3869 7.71667 13.8638 7.71667 13.2098C7.71667 12.5559 8.2398 12.0328 8.89375 12.0328H14.6158C15.2697 12.0328 15.7929 12.5559 15.7929 13.2098C15.7929 13.8638 15.2697 14.3869 14.6158 14.3869ZM14.6158 9.28612H8.89375C8.2398 9.28612 7.71667 8.76299 7.71667 8.10904C7.71667 7.45509 8.2398 6.93196 8.89375 6.93196H14.6158C15.2697 6.93196 15.7929 7.45509 15.7929 8.10904C15.7929 8.76299 15.2697 9.28612 14.6158 9.28612Z" fill="#083D4E"/>
		</svg>
	)
}

