import React, { useState, useEffect, useMemo } from "react";
import "./step11.scss";

import { SelectboxComponent } from "../../../../components/select-box/select-box-component";
import { TextboxComponent } from "../../../../components/textbox/textbox-component";
import useStateHook from "../../../../hooks/state";

import MasterCard from "../../../../images/icons/MasterCard.svg";
import VisaCard from "../../../../images/icons/Visa.svg";
import AmexCard from "../../../../images/icons/Amex.svg";
import CvcIcon from "../../../../images/icons/cvc.svg";

export function Step11(props) {
  const [selectedState, setSelectedState, stateOptions] = useStateHook();
  const [data, setData] = useState({
    number: "",
    expirationDate: "",
    securityCode: "",
    state: "",
    zipCode: "",
  });

  const [expirationDateError, setExpirationDateError] = useState("");
  const [cardType, setCardType] = useState("");

  const detectCardType = (cardNumber) => {
    const patterns = {
      visa: /^4[0-9]{12}(?:[0-9]{3})?$/,
      mastercard: /^5[1-5][0-9]{14}$/,
      amex: /^3[47][0-9]{13}$/,
      // discover: /^6(?:011|5[0-9]{2})[0-9]{12}$/,
    };
    let type = "";

    for (const cardType in patterns) {
      if (patterns[cardType].test(cardNumber)) {
        type = cardType;
      }
    }
    setCardType(type);
  };

  const validateExpirationDate = (expirationMonth, expirationYear) => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear() % 100;
    const currentMonth = currentDate.getMonth() + 1; // January is 0
    let message = "Invalid Expiration Date!";

    if (expirationYear > currentYear) {
      message = "";
    } else if (
      +expirationYear === currentYear &&
      +expirationMonth >= currentMonth
    ) {
      message = "";
    }
    setExpirationDateError(message);
  };

  const formatCardNumber = (cardNumber) => {
    let value = cardNumber.replace(/\D/g, "");

    const match = value.match(/.{1,4}/g);
    if (match) {
      value = match?.join(" ");
    }

    detectCardType(cardNumber);
    return value;
  };

  const handleDateChange = (input) => {
    let formattedInput = input.replace(/\D/g, ""); // Remove non-numeric characters

    if (formattedInput.length > 2) {
      // Ensure that month is between 1 and 12
      const month = formattedInput.slice(0, 2);
      const year = formattedInput.slice(2, 4);

      // Format the expiration date as MM/YY
      formattedInput = `${month}/${year}`;
      validateExpirationDate(month, year);
    }
    return formattedInput;
  };

  const handleChange = (selectedState) => {
    setSelectedState(selectedState);
    props.onChange({ state: selectedState });
    setData({
      ...data,
      state: selectedState,
    });
  };

  const validateValues = {
    expirationDate: handleDateChange,
    number: formatCardNumber,
  };

  const handleTextChange = (field, e) => {
    const value =
      field !== "country" ? e.target.value.replace(/\D/g, "") : e.target.value;
    props.onChange({ [field]: value });

    const validateAndForamt = validateValues[field];

    setData({
      ...data,
      [field]: validateAndForamt?.(value) ?? value,
    });
  };

  useEffect(() => {
    props?.setHasValue((value) => ({
      ...value,
      11: !!(
        data.number &&
        data.expirationDate &&
        data.securityCode &&
        data.state !== "Select State" &&
        data.state !== "" &&
        data.zipCode
      ),
    }));
  }, [data, props?.setHasValue]); // eslint-disable-line react-hooks/exhaustive-deps

  const cardIcon = useMemo(() => {
    const icons = {
      visa: VisaCard,
      mastercard: MasterCard,
      amex: AmexCard,
    };
    return icons[cardType];
  }, [cardType]);

  return (
    <div>
      <h2>To confirm your booking we need a credit card on file...</h2>
      <p className="lead">
        We will only use this if you don’t show up to an appointment or if you
        have a copay.
      </p>
      <div className="step-body">
        <div className="credit-card-form">
          <div className="credit-card-form-row">
            <TextboxComponent
              label="Card Number"
              placeholder="Card Number"
              onChange={handleTextChange.bind(this, "number")}
              max={19}
              value={data.number}
            />
            {cardType ? (
              <img
                src={cardIcon}
                className="card-img-alone"
                alt="CreditCard Icon"
              />
            ) : (
              <div className="card-img">
                <img src={MasterCard} alt="CreditCard Icon" />
                <img src={VisaCard} alt="CreditCard Icon" />
                <img src={AmexCard} alt="CreditCard Icon" />
              </div>
            )}
          </div>
          <div className="credit-card-form-row">
            <div className="credit-card-form-column">
              <TextboxComponent
                label="MM/YY"
                placeholder="MM/YY"
                onChange={handleTextChange.bind(this, "expirationDate")}
                value={data.expirationDate}
                max={5}
                error={expirationDateError}
              />
            </div>
            <div className="credit-card-form-column">
              <TextboxComponent
                label="CVC"
                placeholder="CVC"
                onChange={handleTextChange.bind(this, "securityCode")}
                max={4}
                value={data.securityCode}
              />
              <img src={CvcIcon} alt="" className="cvc-img" />
            </div>
          </div>
          <div className="credit-card-form-row">
            <div className="credit-card-form-country-column">
              <SelectboxComponent
                options={stateOptions}
                value={selectedState}
                onChange={handleChange}
              />
            </div>
            <div className="credit-card-form-zip-column">
              <TextboxComponent
                label="Zip Code"
                placeholder="Zip Code"
                onChange={handleTextChange.bind(this, "zipCode")}
                max={5}
                value={data.zipCode}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
