import "../../global.scss";
import "./home.scss";

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import * as dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import utc from "dayjs/plugin/utc";

import OnboardingService, { OnboardingDataCallbackType } from "../../services/onboarding/onboarding-service";
import {
  AstraIcon,
  ProfileHomeIcon,
  ChatIcon,
  HomeIcon,
} from "../../img/icons";

dayjs.extend(advancedFormat);
dayjs.extend(utc);

function Home() {
  const navigate = useNavigate();
  const [customerName, setCustomerName] = useState("");
  const [apptInfo, setApptInfo] = useState({});
  const [apptDateTime, setApptDateTime] = useState(null);
  const [onboardingService, setOnboardingService] = useState(null);

  useEffect(() => {
    const tOnboardingService = new OnboardingService();
    setOnboardingService(tOnboardingService);

    tOnboardingService.registerCallback(() => {
      const tUserId = tOnboardingService.getUserId();
      const currentStep = tOnboardingService.getStep();
      const userDoc = tOnboardingService.getUserDoc();
      setCustomerName(userDoc.givenName);
      if (tUserId && currentStep < 12) {
        navigate("/onboarding");
      }
    }, OnboardingDataCallbackType.USER_DOC);

    tOnboardingService.registerCallback(() => {
      const tApptInfo = tOnboardingService.getAppointmentInformation();
      if (tApptInfo) {
        setApptInfo(tApptInfo);
        setApptDateTime(dayjs(tApptInfo.utcDate));
      }
    }, OnboardingDataCallbackType.APPT_DOC);
  }, [navigate]);

  const onCompletePaymentClick = () => {
    navigate("/payment");
  };

  const onRescheduleClick = async () => {
    onboardingService.setStep(9);
    await onboardingService.saveActiveDocument();
    navigate("/onboarding");
  };

  const redirectTo = (path) => {
    navigate(path);
  };

  return (
    customerName && (
      <div className="container-fluid">
        <div id="home" className="container">
          <div className="header-icon">
            <AstraIcon />
          </div>
          <h2>Hi, {customerName}!</h2>
          <p className="my-5">Here are some updates for you...</p>
          <div className="content lg">
            <div className="row-center">
              <div className="message-container">
                <div className="empty-message">
                  <p>
                    <em>You have no new messages.</em>
                  </p>
                </div>
              </div>
            </div>
            <div className="row-center">
              <div className="home-block">
                <div className="dark-block">
                  <h6 className="text-center">Upcoming Appointment</h6>
                  <div className="light-block">
                    <h4>{apptDateTime?.format("ddd, MMM Do")}</h4>
                    <p className="my-2">
                      at {apptDateTime?.format("h:mm A")}
                      <br />
                      with {apptInfo.providerName}
                    </p>
                    <button
                      className="btn btn-secondary my-3 mx-auto"
                      disabled={!apptInfo?.isPaid}
                    >
                      Join Zoom Call
                    </button>
                    {/* Hidden for now */}
                    {/* <button className="btn btn-text icon-left mx-auto">
                      <span className="icon">
                        <EmailIcon />
                      </span>
                      <span className="text">
                        Message Dietitian to Reschedule
                      </span>
                    </button> */}
                  </div>
                  <p className="little pt-5 pb-2">
                    You'll be able to join 20 min before your appointment.
                  </p>
                </div>
              </div>
              <div className="home-block">
                <div className="actions-container">
                  <p className="mb-10">
                    We need to collect payment before your appointment.
                  </p>
                  <button
                    className="btn btn-primary mb-5 mx-auto"
                    onClick={onRescheduleClick}
                    type="button"
                    disabled={!!apptInfo?.isPaid}
                  >
                    Reschedule
                  </button>
                  <button
                    className="btn btn-primary mx-auto"
                    onClick={onCompletePaymentClick}
                    type="button"
                    disabled={!!apptInfo?.isPaid}
                  >
                    Complete Payment
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="mobile-nav">
            <div className="row-spaced">
              <button className="active">
                <HomeIcon />
                Home
              </button>
              <button
                onClick={() => {
                  redirectTo("/chat");
                }}
              >
                <ChatIcon />
                Chat
              </button>
              <button
                onClick={() => {
                  redirectTo("/profile");
                }}
              >
                <ProfileHomeIcon />
                Profile
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  );
}

export default Home;
