


export function ChevronRight() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="9" height="16" viewBox="0 0 9 16" fill="none">
			<path d="M0.317013 1.83627L5.99558 7.50427L0.317013 13.1723C0.114222 13.3735 4.19617e-05 13.6473 4.19617e-05 13.933C4.19617e-05 14.2187 0.114216 14.4925 0.317013 14.6937C0.518241 14.8965 0.792012 15.0107 1.07773 15.0107C1.36344 15.0107 1.63721 14.8965 1.83844 14.6937L8.26701 8.26513C8.4698 8.0639 8.58398 7.79013 8.58398 7.50441C8.58398 7.2187 8.46981 6.94493 8.26701 6.7437L1.83844 0.315127C1.56668 0.0433645 1.17058 -0.062787 0.799269 0.0366673C0.428069 0.13611 0.137983 0.426182 0.0385551 0.797382C-0.0608873 1.1687 0.045249 1.5645 0.317013 1.83627Z" fill="#668BB4"/>
		</svg>
	)
}
