



export function AstraIcon() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 45 45" fill="none">
			<path d="M43.822 18.4222C39.3728 11.3771 33.6229 5.63281 26.5834 1.18361C24.0936 -0.390632 20.9117 -0.390632 18.4219 1.18361C11.3769 5.63281 5.63254 11.3771 1.17776 18.4222C-0.396491 20.9119 -0.396491 24.0883 1.17776 26.5837C5.62696 33.6287 11.3769 39.373 18.4163 43.8222C20.9061 45.3965 24.0881 45.3965 26.5778 43.8222C33.6229 39.373 39.3672 33.6231 43.8164 26.5837C45.3906 24.0939 45.3906 20.9119 43.8164 18.4222H43.822ZM22.5027 22.5029V42.4155L18.2042 26.8014L22.5027 22.5029H2.59011L18.2042 18.2045L22.5027 2.59039V22.5029L26.8011 18.2045L42.4152 22.5029H22.5027Z" fill="#083D4E"/>
		</svg>
	)
}
