import "./profile-common.scss";

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import * as dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import utc from "dayjs/plugin/utc";

import OnboardingService, {
  OnboardingDataCallbackType,
} from "../../services/onboarding/onboarding-service";
import { LabelTextField } from "../../components/label-text-combo-field/label-text-combo-field";

import {
  NewChevronLeft,
  HomeIcon,
  ChatIcon,
  ProfileHomeIcon,
} from "../../img/icons";

dayjs.extend(advancedFormat);
dayjs.extend(utc);

export function PersonalInfo(props) {
  const [editMode, setEditMode] = useState(false);
  const [onboardingService, setOnboardingService] = useState(null);

  const navigate = useNavigate();
  const [userData, setUserData] = useState({
    givenName: "",
    familyName: "",
    dateOfBirth: "",
    lineOne: "",
    lineTwo: "",
    city: "",
    state: "",
    postalCode: "",
  });

  const [userInitials, setUserInitials] = useState("");

  useEffect(() => {
    const tOnboardingService = new OnboardingService();
    setOnboardingService(tOnboardingService);

    tOnboardingService.registerCallback(() => {
      const userDoc = tOnboardingService.getUserDoc();
      const newUserData = { ...userData };
      Object.keys(userData).forEach((key) => {
        newUserData[key] = userDoc[key] ?? "";
      });
      setUserData(newUserData);
      const firstInitial = newUserData.givenName[0];
      const lastInitial = newUserData.familyName[0];
      setUserInitials(`${firstInitial}${lastInitial}`);
    }, OnboardingDataCallbackType.USER_DOC);
  }, [navigate]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleFieldChange = (fieldId, value) => {
    const newUserData = { ...userData };
    newUserData[fieldId] = value;

    setUserData(newUserData);
  };

  const getFieldValue = (fieldId, fieldFormat) => {
    let fieldValue = userData[fieldId];
    if (fieldFormat === "date") {
      const dayObj = dayjs(fieldValue);
      if (dayObj.isValid()) {
        fieldValue = dayjs(fieldValue).format("MM-DD-YYYY");
      }
    }

    return fieldValue;
  };

  const toggleEditMode = async () => {
    const newEditMode = !editMode;
    setEditMode(newEditMode);
    if (!newEditMode) {
      onboardingService.saveStepData(userData);
      await onboardingService.saveActiveDocument();
    }
  };

  return (
    <div className="container-fluid has-header">
      <div className="page-header">
        <button className="btn btn-text" onClick={() => navigate("/profile")}>
          <NewChevronLeft />
        </button>
        <h1>Personal Info</h1>
        <button className="btn btn-text sm" onClick={toggleEditMode}>
          {editMode ? "Done" : "Edit"}
        </button>
      </div>
      <div className="container">
        <div className="header-icon">
          <div className="user-avatar">{userInitials}</div>
        </div>
        <div className="content sm">
          <LabelTextField
            isediting={editMode}
            label="First Name"
            fieldid="givenName"
            onChange={handleFieldChange}
            getfieldvalue={getFieldValue}
          />
          <LabelTextField
            isediting={editMode}
            label="Last Name"
            fieldid="familyName"
            onChange={handleFieldChange}
            getfieldvalue={getFieldValue}
          />
          <LabelTextField
            isediting={editMode}
            label="Date of Birth"
            fieldid="dateOfBirth"
            format={"date"}
            onChange={handleFieldChange}
            getfieldvalue={getFieldValue}
          />
          <LabelTextField
            isediting={editMode}
            label="Address (Line One)"
            fieldid="lineOne"
            onChange={handleFieldChange}
            getfieldvalue={getFieldValue}
          />
          <LabelTextField
            isediting={editMode}
            label="Address (Line Two)"
            fieldid="lineTwo"
            onChange={handleFieldChange}
            getfieldvalue={getFieldValue}
          />
          <LabelTextField
            isediting={editMode}
            label="City"
            fieldid="city"
            onChange={handleFieldChange}
            getfieldvalue={getFieldValue}
          />
          <LabelTextField
            isediting={editMode}
            label="State"
            fieldid="state"
            onChange={handleFieldChange}
            getfieldvalue={getFieldValue}
          />
          <LabelTextField
            isediting={editMode}
            label="Zip Code"
            fieldid="postalCode"
            onChange={handleFieldChange}
            getfieldvalue={getFieldValue}
          />
        </div>

        <div className="mobile-nav">
          <div className="row-spaced">
            <button
              onClick={() => {
                navigate("/");
              }}
            >
              <HomeIcon />
              Home
            </button>
            <button
              onClick={() => {
                navigate("/chat");
              }}
            >
              <ChatIcon />
              Chat
            </button>
            <button
              className="active"
              onClick={() => {
                navigate("/profile");
              }}
            >
              <ProfileHomeIcon />
              Profile
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
