




export function PersonalInfoIcon() {
	return (
		<svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M12.7718 14.2237H15.3213C15.6674 14.2171 15.9518 14.4979 15.9518 14.8469C15.9518 15.1953 15.6674 15.4751 15.3213 15.4701H12.7718C12.4275 15.4754 12.1432 15.1953 12.1432 14.8469C12.1429 14.4979 12.4275 14.2175 12.7718 14.2237ZM6.14176 12.7994C7.60243 12.8472 8.72026 13.1188 9.58312 14.7592C9.9035 15.3669 9.98824 16.3639 8.98656 16.3639H3.29897C2.29701 16.3639 2.38215 15.367 2.70078 14.7592C3.56499 13.1188 4.68312 12.8469 6.14176 12.7994ZM12.7718 11.8925H18.296C18.6421 11.8879 18.9271 12.1674 18.9271 12.5157C18.9271 12.8648 18.6421 13.1465 18.296 13.139L12.7718 13.1389C12.4275 13.1465 12.1432 12.8647 12.1432 12.5156C12.1429 12.1673 12.4275 11.8879 12.7718 11.8925ZM12.7718 9.21544H18.296C18.6421 9.20849 18.9271 9.49032 18.9271 9.83867C18.9271 10.1857 18.6421 10.4662 18.296 10.4599H12.7718C12.4275 10.4664 12.1432 10.1857 12.1432 9.83867C12.1429 9.49032 12.4275 9.2082 12.7718 9.21544ZM6.1555 8.31937C7.04032 8.31937 7.75755 9.14787 7.75755 10.1724C7.75755 11.1957 7.0403 12.0248 6.1555 12.0248C5.26945 12.0248 4.55153 11.1957 4.55153 10.1724C4.55153 9.14787 5.26945 8.31937 6.1555 8.31937ZM10.9997 3.00529C11.7088 3.00529 12.2811 3.58141 12.2811 4.29587C12.283 5.00774 11.7085 5.58702 10.9997 5.58702C10.2926 5.58702 9.71796 5.00764 9.71796 4.29587C9.71758 3.58141 10.2925 3.00529 10.9997 3.00529ZM10.9997 0C9.02431 0 7.50234 1.46194 7.03386 3.33996H1.93738C0.916429 3.33996 0 4.09295 0 5.11792V18.2185C0 19.2431 0.918466 20 1.93738 20H20.0619C21.0829 20 22 19.2431 22 18.2185L21.9999 5.11768C21.9999 4.0931 21.0827 3.33971 20.0618 3.33971H14.9656C14.4984 1.46154 12.9747 0.00123583 10.9997 0Z" fill="#668BB4"/>
		</svg>
	)
}

