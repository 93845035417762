import "./step8.scss";

import React from "react";

export function Step8() {
  return (
    <>
      <h2>Your insurance coverage information is now on file.</h2>
      <div className="content md">
        <p className="my-10">
          Astra Health offers a pay per session option, so you can quickly begin
          getting the care you need. In the meantime, we'll keep your insurance
          on file. In the future, it may be possible for insurance to cover your
          care with Astra.
        </p>
        <p className="big text-light">Let's get your appointments scheduled!</p>
      </div>
    </>
  );
}
