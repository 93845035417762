import "./step6.scss";

import React, { useState, useEffect } from "react";

import { ButtonComponent } from "../../../../components/button/button-component";

export function Step6({ onChange, setHasValue }) {
  const [primarySelected, setPrimarySelected] = useState("");
  const [birthSex, setBirthSex] = useState("");

  useEffect(() => {
    onChange({ primaryHolder: primarySelected === "Yes", gender: birthSex });
  }, [primarySelected, birthSex, onChange]);

  useEffect(() => {
    if (setHasValue)
      setHasValue((value) => ({
        ...value,
        6: !!(primarySelected && birthSex),
      }));
  }, [setHasValue, primarySelected, birthSex]);

  const primaryGroupClick = (value) => {
    setPrimarySelected(value);
  };

  const sexGroupClick = (value) => {
    setBirthSex(value);
  };

  return (
    <div>
      <h2>Let's get your health insurance info...</h2>
      <p className="lead">Are you the primary policy holder?</p>
      <div className="content sm">
        <div className="button-group">
          <div className="button-item">
            <ButtonComponent
              text="Yes"
              groupSelected={primarySelected}
              handleClick={primaryGroupClick}
            />
          </div>
          <div className="button-item">
            <ButtonComponent
              text="No"
              groupSelected={primarySelected}
              handleClick={primaryGroupClick}
            />
          </div>
        </div>
        <p className="mt-10 mb-5">What is your birth sex?</p>
        <div className="button-group">
          <div className="button-item">
            <ButtonComponent
              text="Male"
              groupSelected={birthSex}
              handleClick={sexGroupClick}
            />
          </div>
          <div className="button-item">
            <ButtonComponent
              text="Female"
              groupSelected={birthSex}
              handleClick={sexGroupClick}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
