




export function ProfileHomeIcon() {
	return (
		<svg width="21" height="24" viewBox="0 0 21 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M4.285 6.00006C4.285 4.40872 4.91714 2.88258 6.04233 1.75732C7.16758 0.632136 8.69375 0 10.2851 0C11.8764 0 13.4025 0.632136 14.5278 1.75732C15.653 2.88258 16.2851 4.40875 16.2851 6.00006C16.2851 7.59137 15.653 9.11754 14.5278 10.2428C13.4025 11.368 11.8764 12.0001 10.2851 12.0001C8.69375 12.0001 7.16758 11.368 6.04233 10.2428C4.91714 9.11754 4.285 7.59137 4.285 6.00006ZM20.5021 21.0085C19.3964 16.7141 15.1965 13.7143 10.285 13.7143C5.37346 13.7143 1.17369 16.7143 0.0678657 21.0085C-0.10383 21.702 0.0542943 22.4359 0.496436 22.9971C1.00098 23.6273 1.76341 23.9959 2.57072 23.9999H17.9993C18.8186 24.0063 19.5957 23.6367 20.1078 22.9971C20.538 22.4301 20.6835 21.6967 20.5021 21.0085Z" fill="#CAD5DD"/>
		</svg>
	)
}
