/* eslint no-unused-vars: 0 */

import { initializeApp } from "firebase/app";
import {
	EmailAuthProvider,
	signInWithEmailAndPassword,
	createUserWithEmailAndPassword,
	getAuth,
	signInWithPopup,
	onAuthStateChanged,
	setPersistence,
	browserSessionPersistence,
} from "firebase/auth";
// import { collection, doc, getDoc, getDocs, query, where } from "@firebase/firestore"
// import { getFirestore } from "firebase/firestore";

export let activeUser = null;
export let activeUserPromise = null;
let activeUserPromiseResolve = null;
let activeUserPromiseReject = null;
let firebaseAuth = null;

export let firebaseApp = null;

export const FIREBASE_ERROR_MESSAGES = {
	'auth/invalid-email': 'Invalid email address'
	, 'auth/user-not-found': 'User not found'
	, 'auth/wrong-password': 'Invalid password'
	, 'auth/email-already-in-use': 'Email already in use'
	, 'auth/weak-password': 'Password is too weak'
	, 'auth/invalid-password': 'Invalid password'
	, 'auth/invalid-credential': 'Invalid credentials provided'
	, 'unknown': 'Unknown error'
};


const firebaseConfig = {
	apiKey: process.env.REACT_APP_firebaseApiKey,
	authDomain: process.env.REACT_APP_firebaseAuthDomain,
	projectId: process.env.REACT_APP_firebaseProjectId,
	storageBucket: process.env.REACT_APP_firebaseStorageBucket,
	messagingSenderId: process.env.REACT_APP_firebaseMessagingSenderId,
	appId: process.env.REACT_APP_firebaseAppId,
	measurementId: process.env.REACT_APP_firebaseMeasurementId
};

const setUserLoggedIn = (user) => {
	activeUser = {
		uid: user.uid,
		email: user.email,
	};
	activeUserPromiseResolve(activeUser);
};

const createActiveUserPromise = () => {
	activeUserPromise = new Promise((resolve, reject) => {
		activeUserPromiseResolve = resolve;
		activeUserPromiseReject = reject;
	});
	return activeUserPromise;
};

const createApp = () => {
	if (firebaseApp === null) {
		firebaseApp = initializeApp(firebaseConfig);
	}
	return firebaseApp;
};

const createAuth = () => {
	if (firebaseAuth === null) {
		firebaseAuth = getAuth();
	}
	return firebaseAuth;
}

export const authenticateGoogleUser = async () => {
	createActiveUserPromise();
	createApp();
	createAuth();
	await setPersistence(firebaseAuth, browserSessionPersistence);

	onAuthStateChanged(firebaseAuth, (user) => {
		if (user) {
			setUserLoggedIn(user);
		} else {
			activeUserPromiseResolve(null);
		}
	});
	return activeUserPromise;
};

export const getActiveUser = () => {
	return activeUserPromise;
};

export const getFirebaseApp = () => {
	return createApp();
};

export const loginUser = async (email, password) => {
	createActiveUserPromise();
	signInWithEmailAndPassword(firebaseAuth, email, password)
	.then((result) => {
		const user = result.user;
		setUserLoggedIn(user);
		activeUserPromiseResolve({
			error: null
			, auth: true
		});
	})
	.catch((error) => {
		activeUserPromiseReject({
			error
			, auth: false
		});
	});

	return activeUserPromise;
}

export const registerUser = async (email, password) => {
	createActiveUserPromise();
	createUserWithEmailAndPassword(firebaseAuth, email, password)
	.then((result) => {
		const user = result.user;
		setUserLoggedIn(user);
		activeUserPromiseResolve({
			error: null
			, auth: true
		});
	})
	.catch((error) => {
		activeUserPromiseReject({
			error
			, auth: false
		});
	});

	return activeUserPromise;
}

