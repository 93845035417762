import React, { useEffect, useState } from "react";

import { SelectboxComponent } from "../../../../components/select-box/select-box-component";
import { TextboxComponent } from "../../../../components/textbox/textbox-component";
import useStateHook from "../../../../hooks/state";

export function Step7(props) {
  const [, , stateOptions] = useStateHook();

  const [address, setAddress] = useState({
    lineOne: "",
    lineTwo: "",
    city: "",
    state: "", // Added state field
    postalCode: "",
  });

  const handleTextChange = (field, e) => {
    const value =
      field === "postalCode"
        ? e.target.value.replace(/\D/g, "")
        : e.target.value;
    setAddress({ ...address, [field]: value });
    props.onChange({ [field]: value });
  };

  const handleChange = (selectedState) => {
    setAddress({ ...address, state: selectedState });
    props.onChange({ state: selectedState });
  };

  useEffect(() => {
    props?.setHasValue((value) => ({
      ...value,
      7: !!(
        address.city &&
        address.lineOne &&
        address.postalCode &&
        address.state !== "Select State" &&
        address.state !== ""
      ),
    }));
  }, [address, props?.setHasValue]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <h2>What's your address?</h2>
      <p className="lead">
        We use this to verify insurance and licensing information.
      </p>
      <div className="content sm">
        <div className="form-col">
          <TextboxComponent
            placeholder="Address line 1"
            onChange={(e) => handleTextChange("lineOne", e)}
          />
          <TextboxComponent
            placeholder="Address line 2 (optional)"
            onChange={(e) => handleTextChange("lineTwo", e)}
          />
          <TextboxComponent
            placeholder="City"
            onChange={(e) => handleTextChange("city", e)}
          />
          <SelectboxComponent
            options={stateOptions}
            value={address.state}
            onChange={handleChange}
            disableOptionValue="Select State"
          ></SelectboxComponent>
          <TextboxComponent
            placeholder="Zip Code"
            onChange={handleTextChange.bind(this, "postalCode")}
            max="5"
            value={address.postalCode}
          />
        </div>
      </div>
    </div>
  );
}
