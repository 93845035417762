import "./textbox-component.scss";

export function TextboxComponent(props) {
  return (
    <div className="textbox-container">
      <input
        placeholder={props.placeholder}
        type={props.type ?? "text"}
        field={props.field}
        onChange={props.onChange}
        pattern={props?.pattern}
        onBlur={props?.onFocusOut}
        value={props?.value}
        max={props?.max}
        maxLength={props?.max}
      />
      {props?.error && <p className="field-error">{props.error}</p>}
    </div>
  );
}
