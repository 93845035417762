import { getFunctions, httpsCallable } from "firebase/functions";


const sendbirdurl = 'https://api-4D07B854-5880-4770-AFF4-65BABEB9EF31.sendbird.com/v3';
const sendbirdAPIToken = '57b91ba1c6183ba9cf9c2e12de98904a464614a0';

export const createSendbirdUser = async (userId, userNickname) => {
	return await fetch(sendbirdurl + '/users', {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			'Api-Token': sendbirdAPIToken
		},
		body: JSON.stringify({
			"user_id": userId,
			"nickname": userNickname,
			"profile_url": ""
		})
	});
}

export const createSendbirdChannelForUserAndProvider = async ({ userID, userName, providerID, providerName = 'your provider' }) => {
	return await fetch(sendbirdurl + '/group_channels', {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			'Api-Token': sendbirdAPIToken
		},
		body: JSON.stringify({
			"name": `Chat with ${ userName } and ${ providerName }`,
			"cover_url": "",
			"data": "",
			"custom_type": "",
			"user_ids": [ userID, providerID ],
			"is_distinct": true
		})
	});
}

export const fetchSendbirdTokenForUser = async (pUserId) => {
	const functions = getFunctions();
	const fetchSendbirdSessionToken = httpsCallable(functions, 'fetchSendbirdSessionToken');
	return fetchSendbirdSessionToken({ userId: pUserId })
		.then((result) => {
			return result.data.token;
		});
}
