import "./calendar-component.scss";

import React, { useState } from "react";
import * as dayjs from "dayjs";

function PrevButton(props) {
  return (
    <button onClick={() => props.onClick(-1)}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="9"
        height="16"
        viewBox="0 0 9 16"
        fill="none"
      >
        <path
          d="M8.26697 1.83627L2.5884 7.50427L8.26697 13.1723C8.46976 13.3735 8.58394 13.6473 8.58394 13.933C8.58394 14.2187 8.46977 14.4925 8.26697 14.6937C8.06574 14.8965 7.79197 15.0107 7.50626 15.0107C7.22054 15.0107 6.94677 14.8965 6.74554 14.6937L0.316972 8.26513C0.11418 8.0639 0 7.79013 0 7.50441C0 7.2187 0.114175 6.94493 0.316972 6.7437L6.74554 0.315127C7.01731 0.0433645 7.4134 -0.062787 7.78472 0.0366673C8.15592 0.13611 8.446 0.426182 8.54543 0.797382C8.64487 1.1687 8.53874 1.5645 8.26697 1.83627Z"
          fill="#083D4E"
        />
      </svg>
    </button>
  );
}

function NextButton(props) {
  return (
    <button onClick={() => props.onClick(1)}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="9"
        height="16"
        viewBox="0 0 9 16"
        fill="none"
      >
        <path
          d="M0.733028 1.83627L6.4116 7.50427L0.733028 13.1723C0.530237 13.3735 0.416058 13.6473 0.416058 13.933C0.416058 14.2187 0.530231 14.4925 0.733028 14.6937C0.934257 14.8965 1.20803 15.0107 1.49374 15.0107C1.77946 15.0107 2.05323 14.8965 2.25446 14.6937L8.68303 8.26513C8.88582 8.0639 9 7.79013 9 7.50441C9 7.2187 8.88583 6.94493 8.68303 6.7437L2.25446 0.315127C1.98269 0.0433645 1.5866 -0.062787 1.21528 0.0366673C0.844085 0.13611 0.553999 0.426182 0.454571 0.797382C0.355128 1.1687 0.461265 1.5645 0.733028 1.83627Z"
          fill="#083D4E"
        />
      </svg>
    </button>
  );
}

export function CalendarComponent(props) {
  const DATE_COUNT = 9;

  const [curDate, setCurDate] = useState(dayjs());

  const handleMonthButtonClick = (diff) => {
    setCurDate(curDate.add(diff, "months"));
  };

  const handleDatesButtonClick = (diff) => {
    setCurDate(curDate.add(diff, "days"));
  };

  const handleDateClick = (clickedDate) => {
    props.onDateChange(clickedDate);
  };

  const renderDates = () => {
    let dates = [];
    for (let i = 0; i < DATE_COUNT; i++) {
      let date = curDate.add(i, "days");
      let testDate = date.format("YYYY-MM-DD");

      let activeClass = props.activeDates?.has(testDate)
        ? "date-display-active"
        : "date-display-inactive";
      if (testDate === props.selectedDate)
        activeClass = "date-display-selected";

      const dateClass = `date-display ${activeClass}`;
      dates.push(
        <div
          className={dateClass}
          onClick={() => {
            handleDateClick(date);
          }}
          key={`date-${i}`}
        >
          <span className="date-number">{date.format("D")}</span>
          <span className="date-day">{date.format("ddd")}</span>
        </div>
      );
    }
    return dates;
  };

  return (
    <div className="calendar-container">
      <div className="month-container">
        <div className="button-prev-month button-month">
          <PrevButton onClick={handleMonthButtonClick} />
        </div>
        <div className="month-name">{curDate.format("MMMM")}</div>
        <div className="button-next-month button-month">
          <NextButton onClick={handleMonthButtonClick} />
        </div>
      </div>
      <div className="date-scroll">
        <div className="dates-container">
          <div className="button-prev-dates button-dates btn-left">
            <PrevButton onClick={handleDatesButtonClick} />
          </div>
          {renderDates()}
          <div className="button-next-dates button-dates btn-right">
            <NextButton onClick={handleDatesButtonClick} />
          </div>
        </div>
      </div>
    </div>
  );
}
